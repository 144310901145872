import { Div, Header, HorizontalScroll, Separator } from "@vkontakte/vkui";
import { HorizontalPadding } from "../../ui/horizontalPadding";
import { AlbumItem } from "./components/albumItem";
import { InfScroll } from "../../ui/infScroll";
import { ThreeOrTwoCols } from "../../ui/cols";
import { PhotoItemSt } from "./components/photoItem";
import React from "react";
import styled from "styled-components";
import { StartCreateFooter } from "./components/startCreateFooter";
import { PhotosIsLocked } from "./components/photosIsLocked";
import { getImage300Width } from "../../lib/images";
import { ImageItem } from "../../ui/imageItem";

const AlbumsCont = styled.div`
  display: flex;

  > * {
    margin-right: 12px;
  }
`;

const SeparatorSt = styled(Separator)`
  margin-top: 13px;
`;

export const ImageItemSt = styled(ImageItem)`
  cursor: inherit;
`;

export const HorizontalScrollSt = styled(HorizontalScroll)`
  .HorizontalScrollArrow__icon {
    margin-top: -18px;
  }
`;

export const AlreadyAddedPhotosHeader = styled(Header)`
  color: #6d7885;

  .vkuiHeader__content-in {
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    font-weight: normal;
  }
`;

export const ImageItemsCont = styled.div`
  display: flex;

  > * {
    margin-right: 8px;
  }
`;

export const VkPhotosList = ({
  albums,
  onAlbumClick,
  togglePhoto,
  canStartCreate,
  onStartClick,
  onPhotoClick,
  loadMore,
  allDataLoaded,
  photosIsLocked,
  selectedPhotos,
  onlyGroupPhotos,
  alreadyAddedPhotos,
  root,
}) => {
  const noPhotosInGroup = allDataLoaded && onlyGroupPhotos.length === 0;
  if (photosIsLocked || noPhotosInGroup) {
    return <PhotosIsLocked />;
  }
  return (
    <>
      <Header mode="primary">Альбомы</Header>
      <HorizontalPadding>
        <HorizontalScroll showArrows getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
          <AlbumsCont>
            {albums.map((album) => (
              <AlbumItem key={album.id} album={album} onClick={() => onAlbumClick(album)} />
            ))}
          </AlbumsCont>
        </HorizontalScroll>
      </HorizontalPadding>

      <SeparatorSt />

      {alreadyAddedPhotos.length > 0 && (
        <>
          <AlreadyAddedPhotosHeader mode="tertiary">Ранее добавленные фотографии</AlreadyAddedPhotosHeader>
          <HorizontalPadding>
            <HorizontalScrollSt showArrows getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
              <ImageItemsCont>
                {alreadyAddedPhotos.map((photo) => (
                  <ImageItemSt
                    src={getImage300Width(photo.sizes).url}
                    key={photo.id}
                    imgWidth={"80px"}
                    imgHeight={"69px"}
                  />
                ))}
              </ImageItemsCont>
            </HorizontalScrollSt>
          </HorizontalPadding>

          <SeparatorSt />
        </>
      )}

      <Header mode="primary">Все фотографии</Header>
      <HorizontalPadding>
        <InfScroll onReachEnd={loadMore} allDataLoaded={allDataLoaded} root={root}>
          <ThreeOrTwoCols>
            {onlyGroupPhotos.map((photo) => (
              <PhotoItemSt key={photo.id} photo={photo} togglePhoto={togglePhoto} onClick={onPhotoClick} />
            ))}
          </ThreeOrTwoCols>
        </InfScroll>
      </HorizontalPadding>

      <Div style={{ paddingBottom: 90 }} />

      <StartCreateFooter
        canStartCreate={canStartCreate}
        onStartClick={onStartClick}
        photosCount={selectedPhotos.length}
      />
    </>
  );
};
