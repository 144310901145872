import { types } from "mobx-state-tree";
import { router } from "./router";
import { debounce } from "lib/debounce";

export const $modals = types
  .model({
    modal: types.maybeNull(types.string),
  })
  .actions((self) => ({
    show(modal) {
      self.modal = modal;
      router.pushModal(modal);
    },
    _hide() {
      self.modal = null;
      router.popPage();
    },
  }))
  .create({ modal: null });

export const hideModal = debounce(() => {
  $modals._hide();
}, 200);
