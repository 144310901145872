import React from "react";
import {
  Div,
  Panel,
  PanelHeader,
  FormStatus,
  PanelHeaderBack,
  Spacing,
  Separator,
  Button,
  FixedLayout,
} from "@vkontakte/vkui";
import styled from "styled-components";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { observer } from "mobx-react-lite";
import { ThreeOrTwoCols } from "ui/cols";
import { Typo } from "lib/typo";

const Root = styled.div`
  box-sizing: border-box;
  position: relative;
  border-radius: 6px;
  opacity: ${(props) => (props.isValid ? "100%" : "50%")};

  display: inline-block;
`;

const Img = styled.div`
  background: url(${(props) => props.thumb}) no-repeat center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 85%;
  border-radius: 8px;
`;

const PhotoItem = React.forwardRef(({ photo, onClick, className }, ref) => {
  return (
    <Root isValid={photo.isValid} ref={ref} className={className}>
      <Img thumb={photo.calculatedThumb} onClick={() => onClick && onClick(photo)} />
    </Root>
  );
});

const PhotoItemSt = styled(PhotoItem)`
  margin-bottom: 3px;
`;

export const SortPhotosScreen = observer(({ photos, onBackClick, onOrderChanged, onContinueClick }) => {
  const onSortEnd = (oldIndex, newIndex) => {
    onOrderChanged(arrayMove(photos, oldIndex, newIndex));
  };

  return (
    <Panel id="sortPhotos">
      <PanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />} separator={false}>
        Сортировка фотографий
      </PanelHeader>

      <Div style={{ paddingBottom: 100 }}>
        <FormStatus header="Как пользоваться?" mode="default" text={""}>
          <Typo>Передвигайте фотографии в том порядке, в котором вы хотели бы их видеть в карточке товара</Typo>
        </FormStatus>

        <Spacing />

        <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged_item">
          <ThreeOrTwoCols>
            {photos.map((photo) => (
              <SortableItem key={photo.id}>
                <PhotoItemSt key={photo.id} photo={photo} hideCheckbox />
              </SortableItem>
            ))}
          </ThreeOrTwoCols>
        </SortableList>
      </Div>

      <FixedLayout filled vertical="bottom">
        <Separator wide />
        <Div>
          <Div>
            <Button size={"l"} stretched onClick={onContinueClick}>
              Продолжить
            </Button>
          </Div>
        </Div>
      </FixedLayout>
    </Panel>
  );
});
