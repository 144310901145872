import React from "react";
import styled from "styled-components";
import { getInitialHashParams } from "lib/appParams";

const { shortValue } = getInitialHashParams();

const Mark = styled.i`
  color: red !important;
  font-style: normal;
`;

// Перенос предлогов
export const fixTypography = (string, wordLength = 3) => {
  if (!string) return;
  let strSplit = string.split(" "); //разбиваем строку на массив
  strSplit = strSplit.map((str) => (str.length <= wordLength ? str + "\u00A0" : str + " ")); //если слово 3 символа, вставляем символ пробела
  strSplit = strSplit.join(""); //возвращаем обратно массив в строку

  return strSplit;
};

export function Typo({ children }) {
  if (typeof children !== "string") {
    return children;
  }
  return shortValue !== "typo" ? fixTypography(children) : <Mark>{fixTypography(children)}</Mark>;
}

// pluralize(21, ['пользователь', 'пользователя', 'пользователей'])
export function pluralize(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}
