import { types } from "mobx-state-tree";
import { addOnRouterUpdateListeners } from "./router";

export const $appFlow = types
  .model({
    waitPage: types.maybeNull(types.string),
    isLocked: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    lockUntilPage(page) {
      self.waitPage = page;
      self.isLocked = true;
    },
    checkRouter(route) {
      if (self.isLocked && route.pageId === self.waitPage) {
        setTimeout(() => {
          self.unlock();
        }, 300);
      }
    },
    unlock() {
      self.isLocked = false;
      self.waitPage = null;
    },
  }))
  .create({ modal: null });

addOnRouterUpdateListeners((route) => {
  $appFlow.checkRouter(route);
});
