import React, { useRef } from "react";
import { ActionSheetItem, Div, FormStatus, Separator } from "@vkontakte/vkui";
import { PhotosIsLocked } from "./components/photosIsLocked";
import { HorizontalPadding } from "ui/horizontalPadding";
import { InfScroll } from "ui/infScroll";
import { ThreeOrTwoCols } from "ui/cols";
import { PhotoItemSt } from "./components/photoItem";
import { StartCreateFooter } from "./components/startCreateFooter";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Icon24DismissDark } from "@vkontakte/icons";
import { $actions } from "model/actions";
import { $instagram } from "model/instagram";
import { getImage300Width } from "lib/images";
import { AlreadyAddedPhotosHeader, HorizontalScrollSt, ImageItemsCont, ImageItemSt } from "./vkPhotosList";
import { $commonPhotos } from "model/commonPhotos";
import { InstaError } from "./components/instaError";
import { InstaProcessing } from "./components/instaProcessing";
import { FilesInput } from "./components/filesInput";
import { clearPhotosClicked } from "useCases";

const SeparatorSt = styled(Separator)`
  margin-top: 13px;
`;

const HideError = styled(Icon24DismissDark)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

const ErrorCont = styled.div`
  position: relative;
  font-size: 17px;
  line-height: 22px;
`;

const LastArchiveError = observer(() => {
  const needToShowError = $instagram.status === "error" && $instagram.hasPhotos;
  if (!needToShowError) return null;

  return (
    <HorizontalPadding>
      <ErrorCont>
        <HideError width={24} height={24} onClick={() => $instagram.hideArchiveError()} />
        <FormStatus header="Ошибка загрузки файла" mode="error">
          Не удалось загрузить файл с изображениями, попробуйте снова.
        </FormStatus>
      </ErrorCont>
    </HorizontalPadding>
  );
});

const AdditionalButton = styled.div`
  color: #3f8ae0;
  font-size: 17px;
  line-height: 22px;
  user-select: none;
`;

export const InstagramPhotosList = observer(
  ({
    onBackClick,
    photos,
    root,
    loadMore,
    allDataLoaded,
    togglePhoto,
    onPhotoClick,
    canStartCreate,
    selectedPhotos,
    onStartClick,
    alreadyAddedPhotos,
  }) => {
    const inputFile = useRef(null);
    const uploadClicked = () => {
      inputFile.current.click();
    };

    const addPhotosClicked = () => {
      $actions.show(
        <>
          <ActionSheetItem
            onClick={() => {
              $actions.hideNow();
              uploadClicked();
            }}
          >
            Загрузить еще фотографии
          </ActionSheetItem>
          <ActionSheetItem
            onClick={() => {
              $actions.hideNow();
              setTimeout(clearPhotosClicked, 50);
            }}
          >
            Удалить загруженные фото
          </ActionSheetItem>
        </>
      );
    };

    if (
      $instagram.status === "processing" ||
      $instagram.status === "waiting" ||
      $instagram.status === "zipProcessing"
    ) {
      return <InstaProcessing />;
    } else if ($instagram.status === "error" && !$instagram.hasPhotos) {
      return <InstaError />;
    } else if (!$instagram.hasPhotos) {
      return <PhotosIsLocked isInstagram onBackClick={onBackClick} />;
    } else {
      return (
        <>
          {/*<LastArchiveError />*/}

          {alreadyAddedPhotos.length > 0 && (
            <>
              <AlreadyAddedPhotosHeader mode="tertiary">Ранее добавленные фотографии</AlreadyAddedPhotosHeader>
              <HorizontalPadding>
                <HorizontalScrollSt showArrows getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
                  <ImageItemsCont>
                    {alreadyAddedPhotos.map((photo) => (
                      <ImageItemSt
                        src={getImage300Width(photo.sizes).url}
                        key={photo.id}
                        imgWidth={"80px"}
                        imgHeight={"69px"}
                      />
                    ))}
                  </ImageItemsCont>
                </HorizontalScrollSt>
              </HorizontalPadding>

              <SeparatorSt />
            </>
          )}

          <FilesInput inputRef={inputFile} />
          <Div>
            <FormStatus style={{ textAlign: "center" }} onClick={addPhotosClicked}>
              <AdditionalButton>Дополнительно</AdditionalButton>
            </FormStatus>
          </Div>

          <HorizontalPadding>
            <InfScroll onReachEnd={loadMore} allDataLoaded={allDataLoaded} root={root}>
              <ThreeOrTwoCols>
                {photos.map((photo) => (
                  <PhotoItemSt key={photo.id} photo={photo} togglePhoto={togglePhoto} onClick={onPhotoClick} />
                ))}
              </ThreeOrTwoCols>
            </InfScroll>
          </HorizontalPadding>

          <Div style={{ paddingBottom: 90 }} />

          <StartCreateFooter
            canStartCreate={canStartCreate}
            onStartClick={onStartClick}
            photosCount={selectedPhotos.length}
          />
        </>
      );
    }
  }
);
