import React from "react";

export const InfScrollLib = ({ children, loader, onReachEnd, showLoader = true, triggerSize = "50px", root }) => {
  const bottomRef = React.createRef();
  const [isLoading, setLoading] = React.useState(false);

  const scrollCallback = async (entries) => {
    if (entries[0].isIntersecting && !isLoading) {
      setLoading(true);
      await onReachEnd();
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  React.useEffect(() => {
    const scroll = new IntersectionObserver(scrollCallback, {
      root: root || null,
      rootMargin: triggerSize,
    });
    scroll.observe(bottomRef.current);
    return () => {
      scroll.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomRef, root]);

  return (
    <div>
      {children}
      <div ref={bottomRef} />
      {showLoader && loader}
    </div>
  );
};
