import React from "react";
import { observer } from "mobx-react-lite";
import { WithMarketListScreen } from "./withMarketItems";
import { WithoutMarketListScreen } from "./withoutMarketItems";
import { MarketIsLocked } from "./marketIsLocked";

export const MarketListScreen = observer(
  ({ items, onItemClick, onBackClick, onCreateClick, loadMore, allDataLoaded, noMarketItems, marketIsLocked }) => {
    if (marketIsLocked) {
      return <MarketIsLocked onBackClick={onBackClick} />;
    }

    if (noMarketItems) {
      return <WithoutMarketListScreen onCreateClick={onCreateClick} onBackClick={onBackClick} />;
    }

    return (
      <WithMarketListScreen
        items={items}
        onItemClick={onItemClick}
        onBackClick={onBackClick}
        onCreateClick={onCreateClick}
        loadMore={loadMore}
        allDataLoaded={allDataLoaded}
      />
    );
  }
);
