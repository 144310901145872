import React from "react";
import { Panel, Button, PanelHeaderBack, Placeholder } from "@vkontakte/vkui";
import { Icon56MarketOutline } from "@vkontakte/icons";
import { Typo } from "lib/typo";
import { FixedPanelHeader } from "ui/fixedHeader";

export const WithoutMarketListScreen = ({ onBackClick, onCreateClick }) => {
  return (
    <Panel id="marketList">
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>Мои товары</FixedPanelHeader>

      <Placeholder
        icon={<Icon56MarketOutline />}
        header={<Typo>В сообществе нет товаров</Typo>}
        action={
          <Button size="m" onClick={onCreateClick}>
            Создать первый товар
          </Button>
        }
        stretched
      >
        <Typo>Создайте товар, и он появится в сообществе и здесь</Typo>
      </Placeholder>
    </Panel>
  );
};
