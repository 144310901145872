import React from "react";
import { Div, Panel, PanelHeaderBack, Placeholder } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { PhotoItemSt } from "./components/photoItem";
import { ThreeOrTwoCols } from "ui/cols";
import { InfScroll } from "ui/infScroll";
import { StartCreateFooter } from "./components/startCreateFooter";
import { FixedPanelHeader } from "ui/fixedHeader";
import { Typo } from "lib/typo";
import { Icon56GalleryOutline } from "@vkontakte/icons";

export const NoPhotosPlaceholder = ({ onBackClick }) => {
  return (
    <Panel id="photosList">
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>Фотографии</FixedPanelHeader>

      <Placeholder icon={<Icon56GalleryOutline />} header={<Typo>В альбоме нет фотографий</Typo>} stretched>
        <Typo>Добавьте фотографии, чтобы создать товары</Typo>
      </Placeholder>
    </Panel>
  );
};

export const AlbumPhotosListScreen = observer(
  ({
    album,
    photos,
    onBackClick,
    togglePhoto,
    canStartCreate,
    onStartClick,
    onPhotoClick,
    loadMore,
    allDataLoaded,
    selectedPhotos,
  }) => {
    const ref = React.useRef(null);
    const [root, setRoot] = React.useState(null);

    React.useEffect(() => {
      setRoot(ref.current ? ref.current.parentNode : null);
    }, [ref]);

    if (allDataLoaded && photos.length === 0) {
      return <NoPhotosPlaceholder onBackClick={onBackClick} />;
    }

    return (
      <Panel id="albumPhotosList" getRootRef={ref}>
        <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />} separator={false}>
          {album.title}
        </FixedPanelHeader>

        <Div style={{ paddingBottom: 90 }}>
          <InfScroll onReachEnd={loadMore} allDataLoaded={allDataLoaded} root={root}>
            <ThreeOrTwoCols>
              {photos.map((photo) => (
                <PhotoItemSt key={photo.id} photo={photo} togglePhoto={togglePhoto} onClick={onPhotoClick} />
              ))}
            </ThreeOrTwoCols>
          </InfScroll>
        </Div>

        <StartCreateFooter
          canStartCreate={canStartCreate}
          onStartClick={onStartClick}
          photosCount={selectedPhotos.length}
        />
      </Panel>
    );
  }
);
