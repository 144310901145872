import { flow, types } from "mobx-state-tree";
import { getAccessToken } from "api/vkApps";

export const $token = types
  .model({
    token: types.maybeNull(types.string),
  })
  .actions((self) => ({
    fetchToken: flow(function* () {
      const token = yield getAccessToken("market,groups,photos");
      self.token = token;
    }),
  }))
  .create({ token: null });
