import { flow, types } from "mobx-state-tree";
import { callApiMethod } from "api/vkApps";
import { getInitialHashParams, userId } from "lib/appParams";
import { $token } from "./token";
import find from "lodash/find";
import { $instagram } from "./instagram";
import { PAGE_PHOTOS_LIST, router } from "./router";
import { $spinner } from "./spinner";
import { $appFlow } from "./appFlow";

export const $groups = types
  .model({
    groups: types.frozen([]),
    groupsLoaded: types.boolean,
    group: types.frozen({}),
    sourceType: types.optional(types.string, "instagram"),
  })
  .views((self) => ({
    get isAdvanced() {
      return self.group && self.group.market.type === "advanced";
    },
    get isInstagram() {
      return self.sourceType === "instagram";
    },
  }))
  .actions((self) => ({
    fetchUserGroups: flow(function* () {
      self.groupsLoaded = false;
      const token = $token.token;
      const groups = yield callApiMethod(
        "groups.get",
        { user_id: userId, filter: "admin", extended: 1, fields: "members_count,deactivated,market" },
        { token }
      );
      self.groups = groups.items
        .filter((g) => !g.deactivated)
        .sort((a, b) => (a.members_count > b.members_count ? -1 : 1));
      self.groupsLoaded = true;

      const hash = getInitialHashParams();
      if (hash.uploadedToGroup) {
        $appFlow.lockUntilPage(PAGE_PHOTOS_LIST);
        $spinner.show();
        const group = find(self.groups, ["id", parseInt(hash.uploadedToGroup)]);
        self.setGroup(group);
        $instagram.checkStatus();
        $instagram.getUploadedPhotos();
        $groups.setSourceType("instagram");
        setTimeout(() => {
          router.pushPage(PAGE_PHOTOS_LIST);
        }, 500);
        console.log("hash", hash);
        $spinner.hide();
      }
    }),

    setGroup(group) {
      self.group = group;
    },

    setSourceType(value) {
      self.sourceType = value;
    },
  }))
  .create({ groups: [], group: null, groupsLoaded: false });
