import React from "react";
import styled from "styled-components";
import { Caption, Subhead } from "@vkontakte/vkui";

const Root = styled.div`
  cursor: pointer;
  width: 220px;
`;

const Img = styled.div`
  border-radius: 8px;
  width: 220px;
  height: 124px;
  background: url(${(props) => props.thumb}) no-repeat center center;
  background-size: cover;
  margin-bottom: 8px;
`;

const SubheadSt = styled(Subhead)`
  font-weight: normal;
  margin-bottom: 2px;
`;
const CaptionSt = styled(Caption)`
  font-weight: normal;
  color: #818c99;
`;

export function AlbumItem({ album, onClick }) {
  return (
    <Root onClick={onClick}>
      <Img thumb={album.calculatedThumb} />
      <SubheadSt>{album.title}</SubheadSt>
      <CaptionSt level={1}>{album.size} фото</CaptionSt>
    </Root>
  );
}
