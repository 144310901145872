import { applySnapshot, flow, getSnapshot, onSnapshot, types } from "mobx-state-tree";
import { callApiMethod } from "api/vkApps";
import { $token } from "./token";
import { $groups } from "./groups";
import { $photos } from "./photos";
import { $alert } from "./alert";
import { getLargestVkPhoto } from "../lib/images";
import { router } from "./router";
import { $commonPhotos } from "./commonPhotos";

const numberFields = ["price", "dimension_width", "dimension_height", "dimension_length", "weight", "album_id"];

let initialState;

export const $market = types
  .model({
    isEdit: types.boolean,
    items: types.frozen([]),
    noMarketItems: types.boolean,
    totalMarketItemsCount: types.number,
    offset: types.integer,
    allItemsLoaded: types.boolean,
    categories: types.frozen([]),
    albums: types.frozen([]),
    marketIsLocked: types.boolean,
    showAdditionalForm: types.optional(types.boolean, false),
    formItem: types.maybeNull(
      types.model({
        id: types.maybeNull(types.integer),
        title: types.string,
        price: types.number,
        priceString: types.string,
        category_id: types.string,
        description: types.string,
        dimension_width: types.maybeNull(types.number),
        dimension_height: types.maybeNull(types.number),
        dimension_length: types.maybeNull(types.number),
        weight: types.maybeNull(types.number),
        sku: types.maybeNull(types.string),
        album_id: types.maybeNull(types.number),
        stock_amount: types.optional(types.string, ""),
      })
    ),
    formErrors: types.frozen({}),
  })
  .views((self) => ({
    get formHaveErrors() {
      return Object.keys(self.formErrors).length > 0;
    },

    get flatCategories() {
      const res = [];
      self.categories.forEach((cat) => {
        res.push({ ...cat, isRoot: true, children: undefined, id: cat.id.toString() });
        cat.children.forEach((child) => {
          res.push({ ...child, isRoot: false, id: child.id.toString() });
        });
      });
      return res;
    },
  }))
  .actions((self) => ({
    fetchNextMarketItems: flow(function* () {
      const group = $groups.group;
      const token = $token.token;
      const count = 10;
      const itemsResp = yield callApiMethod(
        "market.get",
        { owner_id: -group.id, extended: 1, count, offset: self.offset, with_disabled: 1 },
        { token }
      ).catch((e) => {
        console.error(e);

        const iosAccessDeniedError =
          e.error_data && e.error_data.error_reason && e.error_data.error_reason.error_code === 15;
        const androidAccessDeniedError = e.error_data && e.error_data.error_code === 15;

        if (iosAccessDeniedError || androidAccessDeniedError) {
          return { marketIsLocked: true, error: true };
        }

        $alert.show(
          "Не удалось загрузить список товаров",
          "Ошибка",
          false,
          () => {},
          () => {
            router.popPage();
          }
        );

        self.setError();

        throw e;
      });

      if (!itemsResp.marketIsLocked) {
        self.noMarketItems = itemsResp.count === 0;
        self.allItemsLoaded = self.offset + count >= itemsResp.count;
        self.totalMarketItemsCount = itemsResp.count;
        self.offset += count;
        self.items = [...self.items, ...itemsResp.items];
      } else {
        self.marketIsLocked = true;
      }
    }),

    setError() {
      self.allItemsLoaded = true;
    },

    ensureCategories: flow(function* () {
      try {
        if (self.categories.length) return;
        const token = $token.token;
        const categories = yield callApiMethod("market.getCategories", {}, { token });
        self.categories = categories.items.filter((i) => i.id !== 12);
      } catch (e) {
        $alert.show("Не удалось загрузить список категорий");
      }
    }),

    ensureMarketAlbums: flow(function* () {
      try {
        if (self.albums.length) return;
        const token = $token.token;
        const group = $groups.group;
        const albums = yield callApiMethod("market.getAlbums", { owner_id: -group.id }, { token });
        self.albums = albums.items;
      } catch (e) {
        $alert.show("Не удалось загрузить список подборок");
      }
    }),

    validateMarketItem() {
      const newErrors = {};
      const data = self.formItem;

      if (!data.category_id) newErrors.category_id = "Выберите категорию";

      if (data.title.trim().length < 4) newErrors.title = "Название должно быть не короче 4 символов";
      if (data.title.trim().length > 100) newErrors.title = "Название должно быть не длиннее 100 символов";

      if (data.stock_amount !== "" && isNaN(parseInt(data.stock_amount, 10))) {
        newErrors.stock_amount = "Неверное количество товара";
      }
      if (data.stock_amount > 999999) newErrors.stock_amount = "Количество должно быть не более 999 999";

      if (data.sku && data.sku.length > 50) newErrors.sku = "Поле Артикул должно быть не длиннее 50 символов";

      if (data.dimension_width > 100000) newErrors.dimension_width = "Ширина должна быть не более 100 000";
      if (data.dimension_height > 100000) newErrors.dimension_height = "Высота должна быть не более 100 000";
      if (data.dimension_length > 100000) newErrors.dimension_length = "Длина должна быть не более 100 000";

      if (data.weight > 100000000) newErrors.weight = "Масса должна быть не более 100 000 000";

      if (data.description.trim().length < 10) newErrors.description = "Описание должно быть не короче 10 символов";
      if (data.description.trim().length > 4000)
        newErrors.description = "Описание должно быть не длиннее 4 000 символов";

      const photosLength = $commonPhotos.selectedPhotos.length;
      if (photosLength === 0) newErrors.photos = "Нужно добавить хотя бы одну фотографию";

      self.formErrors = newErrors;
    },

    saveMarketItem: flow(function* () {
      const token = $token.token;
      const group = $groups.group;

      // const PhotosStore = $groups.isInstagram ? $instagram : $photos;
      const PhotosStore = $commonPhotos;

      // prepare photos
      // cover
      const coverPhoto = PhotosStore.selectedPhotos.find((photo) => photo.isCover);
      const restPhotos = PhotosStore.selectedPhotos.filter((photo) => !photo.isCover);
      const mainPhoto = getLargestVkPhoto([...coverPhoto.sizes]);
      const uploadedPhoto = yield $photos.uploadMarketPhoto(mainPhoto.url, true);
      // rest photos
      const largestFromRestPhotos = restPhotos.map((photo) => getLargestVkPhoto([...photo.sizes]));
      const uploadedPhotos = [];
      for (const photo of largestFromRestPhotos) {
        const uploadPhotoRes = yield $photos.uploadMarketPhoto(photo.url, false);
        uploadedPhotos.push(uploadPhotoRes);
      }
      const photo_ids = uploadedPhotos.map((photo) => photo.id).join(",");

      const objectToSend = { ...self.formItem, name: self.formItem.title, main_photo_id: uploadedPhoto.id, photo_ids };
      numberFields.forEach((field) => {
        objectToSend[field] = objectToSend[field] ? parseFloat(objectToSend[field], 10) : 0;
      });
      if (!objectToSend.sku) objectToSend.sku = "";

      // create/update  market
      let itemId;
      const params = { owner_id: -group.id, ...objectToSend };

      if (params.stock_amount === "") delete params.stock_amount;

      if (!self.isEdit) {
        console.info("add", params);
        const res = yield callApiMethod("market.add", params, { token });
        itemId = res.market_item_id;
      } else {
        console.info("save edit", params);
        params.item_id = self.formItem.id;
        yield callApiMethod("market.edit", params, { token });
        itemId = self.formItem.id;
      }

      // set market album
      if (self.formItem.album_id) {
        yield callApiMethod(
          "market.addToAlbum",
          { owner_id: -group.id, item_id: itemId, album_ids: self.formItem.album_id },
          { token }
        ).catch((e) => {
          return {};
          // console.info("save album error", e);
          // if (e.error_data && e.error_data.error_reason.error_code === 1404) {
          //   return null;
          // }
          // throw e;
        });
      }

      return itemId;
    }),

    validateAndSaveMarketItem: function () {
      self.validateMarketItem();
      if (!self.formHaveErrors) {
        return self.saveMarketItem();
      } else {
        return false;
      }
    },

    resetFieldError(fieldName) {
      const newErrors = { ...self.formErrors };
      delete newErrors[fieldName];
      self.formErrors = newErrors;
    },

    startCreateMarket() {
      self.isEdit = false;
      self.showAdditional = false;
      // self.formItem = {
      //   title: "тестовое имя",
      //   price: 0,
      //   category_id: "5001",
      //   description: "тестовое описание тестовое описание ",
      // };
      self.formItem = {
        title: "",
        price: 0,
        priceString: "",
        category_id: "",
        description: "",
      };
    },

    startEditMarket(item) {
      console.log("edit", item);
      self.isEdit = true;
      self.showAdditional = false;

      const price =
        item.price && item.price.amount !== "null"
          ? parseFloat((parseFloat(item.price.amount, 10) / 100).toFixed(2), 10)
          : 0;
      self.formItem = {
        id: item.id,
        title: item.title,
        price: price,
        priceString: price.toString(),
        category_id: item.category ? item.category.id.toString() : null,
        description: item.description,
        sku: item.sku && item.sku !== "null" ? item.sku : "",
        weight: item.weight,
        stock_amount: item.stock_amount ? item.stock_amount.toString() : "",
        album_id: (item.albums_ids && item.albums_ids[0]) || undefined,
      };
      // dimensions
      if (item.dimensions) {
        self.formItem.dimension_width = item.dimensions.width;
        self.formItem.dimension_height = item.dimensions.height;
        self.formItem.dimension_length = item.dimensions.length;
      }
    },

    formOnChange({ name, value }) {
      // number
      if (numberFields.includes(name)) {
        if (value.length > 12) return;

        let newVal = parseFloat(value.replace(/[^\d.]+/g, ""));
        if (newVal < 0) newVal = newVal * -1;
        if (value[value.length - 1] === ".") newVal = 0;

        if (newVal) self.formItem[name] = newVal;
        else self.formItem[name] = 0;
      }
      // количество
      else if (name === "stock_amount") {
        if (value && value.toString().length > 12) return;
        self.formItem[name] = value;
      }
      // default
      else {
        self.formItem[name] = value;
      }

      if (name === "price") {
        self.formItem.priceString = value.replace(/[^\d]+/g, "");
      }

      self.resetFieldError(name);
    },

    setShowAdditional(val) {
      self.showAdditional = val;
    },

    afterCreate() {
      initialState = getSnapshot(self);
    },
    reset() {
      applySnapshot(self, initialState);
    },
  }))
  .create({
    isEdit: false,
    items: [],
    allItemsLoaded: false,
    noMarketItems: false,
    categories: [],
    albums: [],
    formItem: null,
    offset: 0,
    totalMarketItemsCount: 0,
    marketIsLocked: false,
    stock_amount: "",
  });

// reset errors
onSnapshot($commonPhotos.selectedPhotoIds, () => $market.resetFieldError("photos"));
