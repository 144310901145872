import React from "react";
import styled from "styled-components";
import { Panel, PanelHeaderBack, Text, Div } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { StartCreateFooter } from "./components/startCreateFooter";
import { getImage300Width } from "lib/images";
import { Checkbox } from "ui/checkbox";
import { FixedPanelHeader } from "ui/fixedHeader";

const ImageCont = styled.div`
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
  margin-top: -2px;
`;

const Image = styled.div`
  width: 100%;
  padding-top: 100%;

  background: url(${(props) => props.src}) no-repeat center center;
  background-size: contain;
`;

const TextSt = styled(Text)`
  font-weight: normal;
`;

const CheckboxSt = styled(Checkbox)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const PhotosItemScreen = observer(
  ({ photo, onBackClick, togglePhoto, canStartCreate, onStartClick, selectedPhotos, isInstagram }) => {
    return (
      <Panel id="photosList">
        <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>Фотография</FixedPanelHeader>

        <ImageCont onClick={() => togglePhoto(photo)}>
          <CheckboxSt checked={photo.selected} onChange={() => {}} />
          <Image src={isInstagram ? photo.calculatedThumb : getImage300Width(photo.sizes).url} />
        </ImageCont>

        <Div style={{ paddingBottom: 90 }}>
          <TextSt>{photo.text}</TextSt>
        </Div>
        <StartCreateFooter
          canStartCreate={canStartCreate}
          onStartClick={onStartClick}
          photosCount={selectedPhotos.length}
        />
      </Panel>
    );
  }
);
