import { GET } from "./call";
import { getUtmParamsQueryString } from "../lib/appParams";

export async function authorize() {
  return GET("/auth/authorize");
}
export async function init() {
  const utmQueryString = getUtmParamsQueryString();
  console.info({ utmQueryString });
  return GET(`/auth/init?${utmQueryString}`);
}
