export class PublicError extends Error {
  header = null;
  showMessageLink = null;

  constructor(message, header, showMessageLink) {
    super(message);
    this.header = header;
    this.showMessageLink = showMessageLink;
  }
}
