import { applySnapshot, flow, getSnapshot, types } from "mobx-state-tree";
import { checkStatus, getUploadedPhotos, uploadZip } from "api/instagram";
import { $groups } from "./groups";
import { getConfig } from "lib/config";
import { $alert } from "./alert";
import { texts } from "lib/texts";
import { writeMeta } from "api/userMeta";
import { callApiMethod, getAccessToken } from "api/vkApps";
import { uploadMarketPhoto } from "api/vk";

let initialState;

function validateFiles(files) {
  let error = null;
  let archivesCount = 0;
  for (const file of files) {
    if (file.type === "application/zip") archivesCount++;
  }

  if (archivesCount > 1) error = texts.onlyOneArchiveErrorMessage();

  return error;
}

export const $instagram = types
  .model({
    photosMap: types.frozen([]),
    offset: types.optional(types.integer, 0),
    photoIds: types.frozen([]), // id фоток для блока "Все фото"
    allPhotosLoaded: types.optional(types.boolean, false),
    status: types.optional(types.string, ""),
    lastWorkerId: types.optional(types.integer, 0),
    uploadPercent: types.optional(types.integer, 0),
    hasPhotos: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get photos() {
      return self.photoIds.map((id) => self.photosMap[id]);
    },

    get photosWithText() {
      return self.selectedPhotos.filter((photo) => photo.text);
    },
  }))
  .actions((self) => ({
    filesSelected(files) {
      let error = validateFiles(files);
      if (!error) {
        self.uploadFiles(files);
      } else {
        $alert.show(error, "Ошибка загрузки");
      }
    },

    uploadFiles: flow(function* (files) {
      try {
        const groupId = $groups.group.id;
        self.setUploadPercent(0);
        self.setStatus("zipProcessing");
        self.resetPhotos();

        yield uploadZip(files, groupId);
        self.setUploadPercent(100);
        const timer = setInterval(async () => {
          const { hasPhotos } = await self.checkStatus();

          if (self.status === "success") {
            clearInterval(timer);
            self.reset();
            self.setHasPhotos(hasPhotos);
          }

          if (self.status === "error") {
            clearInterval(timer);
          }
        }, 2000);
      } catch (err) {
        self.setStatus("");
        console.log(err.message);
        throw err;
      }
    }),
    getUploadedPhotos: flow(function* () {
      const res = yield getUploadedPhotos($groups.group.id, self.offset);
      const count = 30;
      self.offset += 30;

      self.allPhotosLoaded = res.items.length < count;
      self.photoIds = [...self.photoIds, ...res.items.map((photo) => photo.id)];

      self.addPhotosToMap(res.items);
      // console.log(res);
    }),
    checkStatus: flow(function* () {
      const res = yield checkStatus($groups.group.id);
      self.setStatus(res.status);
      self.setHasPhotos(res.hasPhotos);
      self.setLastWorkerId(res.lastWorkerId);
      return res;
    }),
    fetchNextPhotos: flow(function* () {
      if (self.allPhotosLoaded) return false;
      return self.getUploadedPhotos();
    }),
    setHasPhotos(value) {
      self.hasPhotos = value;
    },
    setStatus(value) {
      self.status = value;
    },
    setLastWorkerId(value) {
      self.lastWorkerId = value || 0;
    },
    setUploadPercent(value) {
      self.uploadPercent = value;
    },

    addPhotosToMap(photos, marketItemId = 0) {
      const { instagramImg } = getConfig();

      const newPhotosMap = {};
      photos.forEach((photo) => {
        const url = `${instagramImg}${photo.path}/${photo.photo}`;
        newPhotosMap[photo.id] = { ...photo };
        newPhotosMap[photo.id].calculatedThumb = url;
        newPhotosMap[photo.id].isValid = true;
        newPhotosMap[photo.id].text = photo.description;
        newPhotosMap[photo.id].marketItemId = marketItemId;
        newPhotosMap[photo.id].photoSource = "instagram";
        newPhotosMap[photo.id].sizes = [
          {
            height: 1000,
            width: 1000,
            url: url,
          },
        ];
      });
      self.photosMap = { ...self.photosMap, ...newPhotosMap };
    },

    uploadPhoto: flow(function* (photo, albumId, token) {
      const server = yield callApiMethod(
        "photos.getUploadServer",
        { album_id: albumId, privacy_view: "only_me" },
        { token }
      );
      const photoUrl = getConfig().appBaseUrl + photo.sizes[0].url;
      const uploadResp = yield uploadMarketPhoto({ uploadServer: server.upload_url, photoUrl });
      const [res] = yield callApiMethod("photos.save", { ...uploadResp, album_id: albumId }, { token });
      return res;
    }),

    uploadPhotosToVk: flow(function* () {
      const token = yield getAccessToken("photos");
      const uploadedPhoto = yield self.uploadPhoto(self.photos[0], 282980351, token);
      console.info({ uploadedPhoto });
    }),

    testGetUserPhotos: flow(function* () {
      const token = yield getAccessToken("photos");
      const alb = yield callApiMethod("photos.getAlbums", {}, { token });
      const foo = yield callApiMethod("photos.get", { album_id: 282980351 }, { token });
      console.info("!", foo);
    }),

    hideArchiveError() {
      self.setStatus("success");
      return writeMeta({ key: "worker_error_closed_" + $instagram.lastWorkerId, value: 1 });
    },

    resetPhotos() {
      self.photoIds = [];
      self.photosMap = {};
      self.offset = 0;
      self.allPhotosLoaded = false;
    },

    afterCreate() {
      initialState = getSnapshot(self);
    },
    reset() {
      applySnapshot(self, initialState);
    },
  }))
  .create();
