import React, { useEffect } from "react";
import styled from "styled-components";
import { Panel, FixedLayout, Button, Div, Separator, PanelHeaderBack } from "@vkontakte/vkui";
import { InfScroll } from "ui/infScroll";
import { ThreeOrTwoCols } from "ui/cols";
import { Typo } from "lib/typo";
import { getImage300Width } from "lib/images";
import { FixedPanelHeader } from "ui/fixedHeader";
import { observer } from "mobx-react-lite";
import { UploadInstagramDump } from "ui/uploadInstagramDump";
import { $instagram } from "model/instagram";

const Img = styled.div`
  background: url(${(props) => props.src}) no-repeat center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 100%;
`;

const ImgRounder = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

const MarketItemHeader = styled.div`
  font-size: 13px;
  line-height: 14px;
  color: #818c99;
  font-weight: normal;
  text-align: center;
  margin-bottom: 4px;
  word-break: break-word;
`;
const MarketItemPrice = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
`;

const MarketItemRoot = styled.div`
  display: inline-table;
  box-sizing: border-box;
  margin-bottom: 16px;
  cursor: pointer;
`;

const MarketItem = ({ item, onClick }) => {
  const { title, price, photos } = item;

  return (
    <MarketItemRoot onClick={onClick}>
      <ImgRounder>
        <Img src={getImage300Width(photos[0].sizes).url} />
      </ImgRounder>
      <MarketItemHeader>
        <Typo>{title}</Typo>
      </MarketItemHeader>
      <MarketItemPrice>{price.text}</MarketItemPrice>
    </MarketItemRoot>
  );
};

export const WithMarketListScreen = observer(
  ({ items, onItemClick, onBackClick, onCreateClick, loadMore, allDataLoaded }) => {
    const ref = React.useRef(null);
    const [root, setRoot] = React.useState(null);

    React.useEffect(() => {
      setRoot(ref.current ? ref.current.parentNode : null);
    }, [ref]);

    useEffect(() => {
      $instagram.checkStatus();

      // setInterval(() => $instagram.checkStatus(), 10000);
    }, []);

    return (
      <Panel id="marketList" getRootRef={ref}>
        <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />} separator={false}>
          Мои товары
        </FixedPanelHeader>

        <UploadInstagramDump />

        <Div style={{ paddingBottom: 90 }}>
          <InfScroll onReachEnd={loadMore} allDataLoaded={allDataLoaded} root={root}>
            <ThreeOrTwoCols>
              {items.map((item) => (
                <MarketItem item={item} key={item.id} onClick={() => onItemClick(item)} />
              ))}
            </ThreeOrTwoCols>
          </InfScroll>
        </Div>

        {items.length > 0 && (
          <FixedLayout filled vertical="bottom">
            <Separator wide />
            <Div>
              <Button size={"l"} stretched onClick={onCreateClick}>
                Создать товар
              </Button>
            </Div>
          </FixedLayout>
        )}
      </Panel>
    );
  }
);
