import React from "react";
import {
  Panel,
  PanelHeaderBack,
  Tabs,
  TabsItem,
  Div,
  Separator,
  Spacing,
  Button,
  FixedLayout,
  FormStatus,
} from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { MainForm } from "./mainForm";
import { AdditionalForm } from "./additionalForm";
import { Typo } from "lib/typo";
import { PAGE_MARKET_CREATE, router } from "model/router";
import { FixedPanelHeader } from "ui/fixedHeader";

export const CreateMarketScreen = observer(
  ({
    item,
    photos,
    categories,
    albums,
    onBackClick,
    formOnChange,
    createMarketClicked,
    onGenerateDescClick,
    onAddPhotoClick,
    errors,
    generateDescAvailable,
    isEdit,
    deletePhotoClicked,
    markCoverClicked,
    sortPhotosClicked,
    photosSorted,
    showAdditional,
    setShowAdditional,
    isAdvanced,
  }) => {
    const hasErrors = Object.keys(errors).length > 0;
    return (
      <Panel id="marketList">
        <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />} separator={false}>
          {isEdit ? "Редактирование товара" : "Создание товара"}
        </FixedPanelHeader>

        <Spacing />
        <div style={{ paddingBottom: 90 }}>
          <Tabs mode="segmented">
            <TabsItem selected={!showAdditional} onClick={() => setShowAdditional(false)}>
              Обязательные
            </TabsItem>
            <TabsItem selected={showAdditional} onClick={() => setShowAdditional(true)}>
              Расширенные
            </TabsItem>
          </Tabs>

          {hasErrors && (
            <Div>
              <FormStatus header="Ошибки в форме" mode="error">
                {Object.keys(errors).map((key) => (
                  <div key={key}>
                    <Typo>{errors[key]}</Typo>
                  </div>
                ))}
              </FormStatus>
            </Div>
          )}

          {!showAdditional ? (
            <MainForm
              item={item}
              photos={photos}
              errors={errors}
              generateDescAvailable={generateDescAvailable}
              onAddPhotoClick={onAddPhotoClick}
              categories={categories}
              formOnChange={formOnChange}
              onGenerateDescClick={onGenerateDescClick}
              deletePhotoClicked={deletePhotoClicked}
              markCoverClicked={markCoverClicked}
              sortPhotosClicked={sortPhotosClicked}
              photosSorted={photosSorted}
            />
          ) : (
            <AdditionalForm
              item={item}
              errors={errors}
              albums={albums}
              formOnChange={formOnChange}
              isAdvanced={isAdvanced}
            />
          )}
        </div>

        <FixedLayout filled vertical="bottom">
          <Separator wide />
          <Div>
            <Button size={"l"} stretched mode="primary" onClick={createMarketClicked}>
              {isEdit ? "Сохранить" : "Создать"}
            </Button>
          </Div>
        </FixedLayout>
      </Panel>
    );
  }
);
