import bridge from "@vkontakte/vk-bridge";
import { ANALYTICS_EVENTS, sendAnalytics } from "lib/analytics";
import { getInitialHashParams } from "./lib/appParams";
import { authUser } from "./useCases";

sendAnalytics(ANALYTICS_EVENTS.appStarted);

export const hash = getInitialHashParams();

bridge.send("VKWebAppInit");

function start() {
  if (hash.shortValue === "fromStory") {
    sendAnalytics(ANALYTICS_EVENTS.visitFromStory);
  }
  if (hash.shortValue === "join") {
    sendAnalytics(ANALYTICS_EVENTS.visitFromPost);
  }
}

async function makeInitRequests() {
  await authUser();
  start();
}

makeInitRequests();
