import React from "react";
import styled from "styled-components";
import { Checkbox } from "ui/checkbox";

const Root = styled.div`
  box-sizing: border-box;
  position: relative;
  border-radius: 6px;
  opacity: ${(props) => (props.isValid ? "100%" : "50%")};

  display: inline-block;
`;

const ImgRounder = styled.div`
  border-radius: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

const Img = styled.div`
  background: url(${(props) => props.thumb}) no-repeat center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 85%;
`;

const CheckboxSt = styled(Checkbox)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export function PhotoItem({ photo, onClick, togglePhoto, hideCheckbox, className }) {
  return (
    <Root isValid={photo.isValid} className={className} onClick={() => onClick && onClick(photo)}>
      <ImgRounder>
        <Img thumb={photo.calculatedThumb} />
      </ImgRounder>
      {!hideCheckbox && (
        <CheckboxSt
          checked={photo.selected}
          onChange={() => {}}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            togglePhoto(photo);
          }}
        />
      )}
    </Root>
  );
}

export const PhotoItemSt = styled(PhotoItem)`
  margin-bottom: 3px;
  cursor: pointer;
`;
