import { types } from "mobx-state-tree";
import { POPOUT_CONFIRM, router } from "./router";

export const $confirm = types
  .model({})
  .actions((self) => ({
    show(
      header,
      message,
      {
        yesButtonTitle = "Да",
        yesButtonClick,
        noButtonTitle = "Отмена",
        noButtonClick,
        noButtonConfig = {},
        yesButtonConfig = {},
      }
    ) {
      const actions = [];
      actions.push({
        title: noButtonTitle,
        mode: "cancel",
        action: () => {
          noButtonClick && noButtonClick();
          self.hide();
        },
        ...noButtonConfig,
      });
      actions.push({
        title: yesButtonTitle,
        action: () => {
          yesButtonClick && yesButtonClick();
          self.hide();
        },
        ...yesButtonConfig,
      });

      router.pushPopup(POPOUT_CONFIRM, { message, header, actions });
    },
    hide() {
      router.popPage();
    },
  }))
  .create();
