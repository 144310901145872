import React from "react";
import { Button, Panel, PanelHeaderBack, Placeholder, Spacing } from "@vkontakte/vkui";
import { Typo } from "lib/typo";
import { FixedPanelHeader } from "ui/fixedHeader";
import { Icon56GoodsCollection } from "@vkontakte/icons";

export const NoAlbums = ({ onBackClick }) => {
  return (
    <Panel id="selectMarketAlbumScreen">
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>
        Выбор подборки
      </FixedPanelHeader>

      <Spacing />

      <Placeholder
        icon={<Icon56GoodsCollection />}
        header={<Typo>В сообществе нет подборок товаров</Typo>}
        stretched
        action={
          <Button size="m" as="a" href="https://vk.com/@ecom-obnovlennyi-magazin" target="_blank">
            Читать инструкцию
          </Button>
        }
      >
        <Typo>Создайте подборку, чтобы добавлять в неё товары</Typo>
      </Placeholder>
    </Panel>
  );
};
