import React from "react";
import styled from "styled-components";
import { Panel, RichCell, Avatar, Spacing, PanelHeaderButton } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { groupSelected } from "useCases";
import { pluralize } from "lib/typo";
import { FixedPanelHeader } from "ui/fixedHeader";
import { Icon24HelpOutline } from "@vkontakte/icons";
import { NoGroups } from "./noGroups";

const RichCellSt = styled(RichCell)`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const GroupsListScreen = observer(({ groups, groupsLoaded, onHelpClick }) => {
  if (groupsLoaded && groups.length === 0) {
    return <NoGroups onHelpClick={onHelpClick} />;
  }

  return (
    <Panel id="groupsList">
      <FixedPanelHeader
        left={
          <PanelHeaderButton onClick={onHelpClick}>
            <Icon24HelpOutline />
          </PanelHeaderButton>
        }
      >
        Сообщества
      </FixedPanelHeader>
      <Spacing />

      {groups.map((group) => (
        <div key={group.id} onClick={() => groupSelected(group)}>
          <RichCellSt
            disabled
            multiline
            before={<Avatar size={48} src={group.photo_50} />}
            caption={
              group.members_count &&
              `${group.members_count} ${pluralize(group.members_count, ["подписчик", "подписчика", "подписчиков"])}`
            }
          >
            {group.name}
          </RichCellSt>
        </div>
      ))}
    </Panel>
  );
});
