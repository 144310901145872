import React from "react";
import { observer } from "mobx-react-lite";
import { GroupsListScreen } from "screens/groupsList";
import { $groups } from "model/groups";
import { loadGroupsList } from "useCases";
import { MODAL_HELP } from "model/router";
import { $modals } from "model/modals";

export const GroupsList = observer(() => {
  React.useEffect(() => {
    loadGroupsList();
  }, []);
  return (
    <GroupsListScreen
      groups={$groups.groups}
      groupsLoaded={$groups.groupsLoaded}
      onHelpClick={() => $modals.show(MODAL_HELP)}
    />
  );
});
