import axios from "axios";
import { queryParams } from "lib/appParams";
import { getConfig } from "lib/config";
import { $debug } from "model/debug";
import { $alert } from "model/alert";

const { backUrl } = getConfig();

const instance = axios.create({
  baseURL: `${backUrl || ""}/`,
  timeout: 0,
  headers: {
    queryparams: JSON.stringify({ ...queryParams }),
  },
});

instance.interceptors.request.use((data) => {
  $debug.log(JSON.stringify(data));
  return data;
});

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.code === "ECONNABORTED" || (error.response && error.response.status === 502)) {
      console.info("failed load");
    }

    const { url } = error.config;

    if (url !== "/anl") {
      $alert.show("Что-то пошло не так. Попробуйте позже.", "Ошибка", true);
    }

    return Promise.reject(error);
  }
);

export function GET(url, query, config = {}) {
  return instance.get(url, { params: query, ...config });
}

export function POST(url, data, config) {
  return instance.post(url, data, config);
}

export function PUT(url, data) {
  return instance.put(url, data);
}

export function DEL(url) {
  return instance.delete(url);
}

export function sleep(timeout = 1) {
  return new Promise((res) => setTimeout(res, timeout));
}
