import React from "react";
import { observer } from "mobx-react-lite";
import { $market } from "model/market";
import { marketAlbumSelected } from "useCases";
import { router } from "model/router";
import { SelectMarketAlbumScreen } from "screens/selectMarketAlbum";
import { debounce } from "lib/debounce";

const debouncedMarketAlbumSelected = debounce(marketAlbumSelected, 100);

export const SelectMarketAlbum = observer(() => {
  return (
    <SelectMarketAlbumScreen
      albums={$market.albums}
      albumId={$market.formItem.album_id}
      onSelected={debouncedMarketAlbumSelected}
      onBackClick={() => router.popPage()}
    />
  );
});
