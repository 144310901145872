import React from "react";
import { observer } from "mobx-react-lite";
import { Button, ModalCard } from "@vkontakte/vkui";
import styled from "styled-components";
import { Typo } from "lib/typo";
import { i } from "lib/images";
import logo from "./imgs/welcomeBack.png";
import { $user } from "model/user";

const Img = styled.img`
  width: 72px;
  height: 72px;
`;

const Header = styled.div`
  font-family: VKSansDisplayBold, sans-serif;
  font-weight: 600;
`;

export const WelcomeBackModal = observer(({ id, onClose }) => {
  return (
    <ModalCard
      id={id}
      icon={<Img src={i(logo)} />}
      header={
        <Header>
          <Typo>С возвращением, {$user.vkUser.first_name}!</Typo>
        </Header>
      }
      subheader={<Typo>Выберите сообщество, в котором хотите создать или изменить товары</Typo>}
      actions={
        <Button size="l" mode="primary" onClick={onClose}>
          Продолжить
        </Button>
      }
    />
  );
});
