import { flow, onSnapshot, types } from "mobx-state-tree";
import { init } from "api/auth";
import { queryParams } from "lib/appParams";

const pushAllowed = parseInt(queryParams.vk_are_notifications_enabled) === 1;

export const $user = types
  .model({
    user: types.frozen({}),
    vkUser: types.frozen({}),
    promocode: types.optional(types.maybeNull(types.string), null),
    pushAllowed: types.optional(types.boolean, pushAllowed),
  })
  .actions((self) => ({
    authUser: flow(function* () {
      try {
        const { serverUser, vkUser } = yield init();
        self.user = serverUser;
        self.vkUser = vkUser;
      } catch (error) {
        console.error("get vk user error: ", error);
      }
    }),
    setPromocode(val) {
      self.promocode = val;
    },
    setPushAllowed(value) {
      self.pushAllowed = value;
    },
  }))
  .create();
