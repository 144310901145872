import { types } from "mobx-state-tree";
import { POPOUT_ACTIONS, router } from "./router";
import { debounce } from "lib/debounce";

export const $actions = types
  .model({
    actionItems: types.frozen(null),
  })
  .actions((self) => ({
    show(actionItems) {
      self.actionItems = { node: actionItems };
      router.pushPopup(POPOUT_ACTIONS);
    },
    hide: debounce(() => {
      router.popPage();
    }, 100),
    hideNow: () => {
      router.popPage();
    },
  }))
  .create({ actionItems: null });
