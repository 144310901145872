import { onSnapshot, types } from "mobx-state-tree";
import { $photos } from "./photos";
import { $instagram } from "./instagram";
import { $alert } from "./alert";

function fillPhoto(photo, model) {
  return { ...photo, selected: model.selectedPhotoIds.includes(photo.id), isCover: model.coverId === photo.id };
}

export const $commonPhotos = types
  .model({
    selectedPhotoIds: types.array(types.integer), // id выбранных юзером фоток
    alreadySelectedPhotoIds: types.array(types.integer), // id выбранных юзером фоток до нажатия кнопки "добавить еще"
    coverId: types.maybeNull(types.integer),
    openedPhotoId: types.maybeNull(types.integer), // id открытой фотки
  })
  .views((self) => ({
    get onlyGroupPhotos() {
      return $photos.onlyGroupPhotos.map((photo) => fillPhoto(photo, self));
    },

    get instagramPhotos() {
      return $instagram.photos.map((photo) => fillPhoto(photo, self));
    },

    get selectedPhotos() {
      return self.selectedPhotoIds.map((id) => fillPhoto(self.photosMap[id], self));
    },

    get albumPhotos() {
      return $photos.albumPhotoIds.map((id) => fillPhoto(self.photosMap[id], self));
    },

    // фотографии, "вшитые в товар"
    get alreadyAddedPhotos() {
      return self.alreadySelectedPhotoIds.map((id) => fillPhoto(self.photosMap[id], self));
    },

    get photosMap() {
      return { ...$photos.photosMap, ...$instagram.photosMap };
    },

    get canStartCreate() {
      return self.selectedPhotoIds.length > 0;
    },

    get photosWithText() {
      return self.selectedPhotos.filter((photo) => photo.text);
    },

    get openedPhoto() {
      return self.openedPhotoId ? fillPhoto(self.photosMap[self.openedPhotoId], self) : null;
    },
  }))
  .actions((self) => ({
    togglePhotoSelect(photoId) {
      if (!self.photosMap[photoId].isValid) {
        $alert.show("Фотография должна быть не менее 400х400 px", "Фото слишком маленькое");
        return;
      }
      if (self.selectedPhotoIds.length === 5 && !self.selectedPhotoIds.includes(photoId)) {
        $alert.show("К товару можно прикрепить не более 5 фотографий");
        return;
      }

      if (self.selectedPhotoIds.includes(photoId)) {
        self.selectedPhotoIds = self.selectedPhotoIds.filter((id) => id !== photoId);
      } else {
        self.selectedPhotoIds.push(photoId);
      }

      self.ensureCover();
    },

    saveAlreadyAddedPhotos() {
      self.alreadySelectedPhotoIds = [...self.selectedPhotoIds];
    },

    setSelectedPhotos(ids) {
      self.selectedPhotoIds = ids;
      self.ensureCover();
    },

    ensureCover() {
      if (self.selectedPhotoIds.length > 0 && !self.selectedPhotoIds.includes(self.coverId)) {
        self.coverId = self.selectedPhotoIds[0];
      }
    },

    ensureCoverIsFirst() {
      if (self.coverId) {
        const rest = self.selectedPhotoIds.filter((id) => id !== self.coverId);
        self.selectedPhotoIds = [self.coverId, ...rest];
      }
    },

    setCover(photoId) {
      self.coverId = photoId;
      self.ensureCoverIsFirst();
    },

    setOpenedPhoto(photoId) {
      self.openedPhotoId = photoId;
    },

    /**
     * Сбрасываем выбор у загруженных руками фоток перед их удалением
     */
    resetSelectOnUploadedPhotos() {
      const newSelectedPhotoIds = self.selectedPhotos
        .filter((photo) => photo.photoSource !== "instagram")
        .map((photo) => photo.id);
      $commonPhotos.setSelectedPhotos(newSelectedPhotoIds);
      $commonPhotos.saveAlreadyAddedPhotos();
    },
  }))
  .create({ selectedPhotoIds: [] });
