import React from "react";
import { observer } from "mobx-react-lite";
import { $photos } from "model/photos";
import { router } from "model/router";
import { AlbumPhotosListScreen } from "screens/photos/albumPhotosList";
import { openPhotoClicked, startMarketCreateClicked } from "../../useCases";
import { $commonPhotos } from "../../model/commonPhotos";

export const AlbumPhotosList = observer(() => {
  return (
    <AlbumPhotosListScreen
      album={$photos.selectedAlbum}
      photos={$commonPhotos.albumPhotos}
      canStartCreate={$commonPhotos.canStartCreate}
      togglePhoto={(photo) => $commonPhotos.togglePhotoSelect(photo.id)}
      onStartClick={startMarketCreateClicked}
      onPhotoClick={openPhotoClicked}
      onBackClick={() => router.popPage()}
      loadMore={$photos.fetchNextAlbumPhotos}
      allDataLoaded={$photos.allAlbumPhotosLoaded}
      selectedPhotos={$commonPhotos.selectedPhotos}
    />
  );
});
