import React from "react";
import { Panel, Group, List, Cell, PanelHeaderBack } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { Icon24Done } from "@vkontakte/icons";
import { FixedPanelHeader } from "ui/fixedHeader";
import { NoAlbums } from "./noAlbums";

export const SelectMarketAlbumScreen = observer(({ albums, albumId, onBackClick, onSelected }) => {
  if (albums.length === 0) {
    return <NoAlbums onBackClick={onBackClick} />;
  }

  return (
    <Panel id="selectMarketAlbumScreen">
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>
        Выбор подборки
      </FixedPanelHeader>

      <Group>
        <List>
          {albums.map((album) => (
            <Cell
              key={album.id}
              onClick={() => onSelected(album)}
              after={album.id === albumId ? <Icon24Done fill="var(--accent)" /> : null}
            >
              {album.title}
            </Cell>
          ))}
        </List>
      </Group>
    </Panel>
  );
});
