import React from "react";
import { useLocation } from "@happysanta/router";
import { Root, ScreenSpinner, View } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import {
  PANEL_ALBUM_PHOTOS_LIST,
  PANEL_GROUPS_LIST,
  PANEL_MARKET_CREATE,
  PANEL_MARKET_LIST,
  PANEL_PHOTO_ITEM,
  PANEL_PHOTOS_LIST,
  PANEL_SELECT_MARKET_ALBUM,
  PANEL_SELECT_RUBRIC,
  PANEL_SORT_PHOTOS,
  PANEL_WELCOME,
  router,
  VIEW_MAIN,
  VIEW_SELECT,
} from "./model/router";
import { $spinner } from "./model/spinner";
import { Welcome } from "./pages/welcome";
import { Modals } from "./modals";
import { SnackbarCont } from "./snackbarCont";
import { GroupsList } from "./pages/groupsList";
import { MarketList } from "./pages/marketList";
import { PhotosList } from "./pages/photosList";
import { AlbumPhotosList } from "./pages/albumPhotosList";
import { PhotoItem } from "./pages/photoItem";
import { CreateMarket } from "./pages/createMarket";
import { SortPhotos } from "./pages/sortPhotos";
import { getPopouts } from "./popouts";
import { SelectRubric } from "./pages/selectRubric";
import { SelectMarketAlbum } from "./pages/selectMarketAlbum";
import vkBridge from "@vkontakte/vk-bridge";
import { $user } from "./model/user";

const ViewTestsApp = React.lazy(() => import("./viewTests"));

export const App = () => {
  let res = <ClientApp />;

  if (window.location.search.includes("viewTests")) {
    res = (
      <React.Suspense fallback={<div>Загрузка...</div>}>
        <View id={VIEW_MAIN} activePanel="view_tests">
          <ViewTestsApp id="view_tests" />
        </View>
      </React.Suspense>
    );
  }

  return res;
};

export const ClientApp = observer(() => {
  const location = useLocation();

  let activeView = location.getViewId();
  const params = router.getCurrentLocation().getParams();
  const history = location.state.history;

  vkBridge.subscribe((e) => {
    if (e.detail.type === "VKWebAppViewRestore") {
      $user.checkHash();
    }
  });

  return (
    <>
      <SnackbarCont />
      <Root popout={getPopouts(location)} activeView={activeView} modal={<Modals />}>
        <View
          id={VIEW_MAIN}
          activePanel={location.getViewActivePanel(VIEW_MAIN)}
          popout={$spinner.active && <ScreenSpinner />}
          history={history}
          onSwipeBack={() => router.popPage()}
        >
          <Welcome id={PANEL_WELCOME} />
          <GroupsList id={PANEL_GROUPS_LIST} />
          <MarketList id={PANEL_MARKET_LIST} />
          <PhotosList id={PANEL_PHOTOS_LIST} />
          <AlbumPhotosList id={PANEL_ALBUM_PHOTOS_LIST} />
          <PhotoItem id={PANEL_PHOTO_ITEM} />
          <CreateMarket id={PANEL_MARKET_CREATE} params={params} />
          <SortPhotos id={PANEL_SORT_PHOTOS} />
        </View>
        <View
          id={VIEW_SELECT}
          activePanel={location.getViewActivePanel(VIEW_SELECT)}
          popout={$spinner.active && <ScreenSpinner />}
        >
          <SelectRubric id={PANEL_SELECT_RUBRIC} />
          <SelectMarketAlbum id={PANEL_SELECT_MARKET_ALBUM} />
        </View>
      </Root>
    </>
  );
});
