import React from "react";
import { observer } from "mobx-react-lite";
import { ModalPage, ContentCard, ModalPageHeader, Spacing, Caption } from "@vkontakte/vkui";
import styled from "styled-components";
import { $photos } from "model/photos";
import { selectedPhotoForDescription } from "useCases";
import { Typo } from "lib/typo";
import { HorizontalPadding } from "ui/horizontalPadding";
import { $groups } from "../../../model/groups";
import { $instagram } from "../../../model/instagram";
import { $commonPhotos } from "../../../model/commonPhotos";

const ModalPageHeaderSt = styled(ModalPageHeader)`
  .vkuiModalPageHeader__content {
    font-size: 19px;
    font-family: VKSansDisplayBold, sans-serif;
    font-weight: 600;
  }

  .vkuiModalPage--android .vkuiModalPageHeader__in {
    padding: 0;
    font-family: VKSansDisplayBold, sans-serif;
    font-weight: 600;
  }

  span {
    font-family: VKSansDisplayBold, sans-serif;
    font-weight: 600;
  }
`;

const ContentCardSt = styled(ContentCard)`
  margin-bottom: 12px;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
`;

const CaptionSt = styled(Caption)`
  color: #6d7885;
  margin-bottom: 20px;
`;

const CardDesc = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GenerateDescriptionModal = observer(({ id, onClose }) => {
  return (
    <ModalPage id={id} header={<ModalPageHeaderSt>Выберите описание</ModalPageHeaderSt>}>
      <HorizontalPadding>
        <CaptionSt level={1} weight="regular">
          <Typo>
            Описание фотографии можно использовать в качестве описания товара. Выберите, какое подходит лучше, или
            напишите новое.
          </Typo>
        </CaptionSt>

        {$commonPhotos.photosWithText.map((photo) => (
          <ContentCardSt
            mode={"tint"}
            text={<CardDesc>{photo.text}</CardDesc>}
            key={photo.id}
            onClick={() => {
              selectedPhotoForDescription(photo);
              onClose();
            }}
          />
        ))}
      </HorizontalPadding>
      <Spacing />
    </ModalPage>
  );
});
