let domain = null;

const scripts = document.getElementsByTagName("script");
for (let i = 0; i < scripts.length; i++) {
  const scriptSrc = scripts[i].src;
  if (scriptSrc.includes("/env.js")) {
    domain = new URL(scriptSrc).host;
  }
}

export function i(url) {
  if (domain) return url[0] === "/" ? `https://${domain}${url}` : `https://${domain}/${url}`;
  else return url;
}

export function getLargestVkPhoto(sizes) {
  const res = sizes.sort((a, b) => (a.width < b.width ? 1 : -1));
  return res[0];
}

export function getImage300Width(sizes = []) {
  const yPhoto = sizes.find((image) => image.type === "y");
  const xPhoto = sizes.find((image) => image.type === "x");
  return yPhoto || xPhoto || sizes[0];
}
