import React from "react";
import { Panel, Placeholder, PanelHeaderBack, Button } from "@vkontakte/vkui";
import { Icon56MarketOutline } from "@vkontakte/icons";
import { Typo } from "lib/typo";
import { FixedPanelHeader } from "ui/fixedHeader";

export const MarketIsLocked = ({ onBackClick }) => {
  return (
    <Panel id="marketList">
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>Мои товары</FixedPanelHeader>

      <Placeholder
        icon={<Icon56MarketOutline />}
        header={<Typo>Включите раздел «Товары» в сообществе</Typo>}
        stretched
        action={
          <Button size="m" as="a" href="https://vk.com/@ecom-obnovlennyi-magazin" target="_blank">
            Читать инструкцию
          </Button>
        }
      >
        <Typo>Для этого зайдите в настройки сообщества, затем в «Разделы» и включите «Товары»</Typo>
      </Placeholder>
    </Panel>
  );
};
