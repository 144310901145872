import { createGlobalStyle } from "styled-components";
import { i } from "lib/images";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "VKSansDisplayBold";
    src: url("${i("/fonts/VK Sans Display DemiBold.ttf")}") format("opentype");
    font-display: swap;
  }
`;
