import React from "react";
import { observer } from "mobx-react-lite";
import { ModalRoot } from "@vkontakte/vkui";
import { useLocation } from "@happysanta/router";
import { MODAL_GENERATE_DESCRIPTION, MODAL_HELP, MODAL_WELCOME_BACK } from "model/router";
import { GenerateDescriptionModal } from "pages/createMarket/modals/generateDescriptionModal";
import { HelpModal } from "pages/groupsList/modals/helpModal";
import { WelcomeBackModal } from "./pages/groupsList/modals/welcomeBackModal";
import { $modals, hideModal } from "./model/modals";

export const Modals = observer(() => {
  const location = useLocation();

  let modalId = location.getModalId();
  if ($modals.modal === null) modalId = null;

  return (
    <ModalRoot activeModal={modalId}>
      <GenerateDescriptionModal id={MODAL_GENERATE_DESCRIPTION} onClose={hideModal} />
      <HelpModal id={MODAL_HELP} onClose={hideModal} />
      <WelcomeBackModal id={MODAL_WELCOME_BACK} onClose={hideModal} />
    </ModalRoot>
  );
});
