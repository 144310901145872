import React, { useRef } from "react";
import styled from "styled-components";
import { Button, Placeholder } from "@vkontakte/vkui";
import imageIconRaw from "./img/imageIcon.svg";
import alertIconRaw from "./img/alertIcon.svg";
import { Typo } from "lib/typo";
import { i } from "lib/images";
import { observer } from "mobx-react-lite";
import { FilesInput } from "./filesInput";

const imageIcon = i(imageIconRaw);
const alertIcon = i(alertIconRaw);

const Icon = styled.img`
  width: 56px;
`;

export const PlaceholderSt = styled(Placeholder)`
  .vkuiPlaceholder__in {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const PhotosIsLocked = observer(({ isInstagram }) => {
  const inputFile = useRef(null);

  const uploadClicked = () => {
    inputFile.current.click();
  };

  return isInstagram ? (
    <PlaceholderSt
      icon={<Icon src={alertIcon} />}
      header="Загрузите файлы"
      action={
        <Button size={"m"} onClick={uploadClicked}>
          Выбрать файл
        </Button>
      }
    >
      <Typo>
        Вы можете загрузить фотографии или архив, который скачали из другой соцсети или создали сами.
        <br />
        <br />
        Фотографии загружаются мгновенно. Загрузка и обработка архива занимают до 24 часов.
        <br />
        <br />
        Во время загрузки архива (до 10 минут) закрыть мини-приложение нельзя.{" "}
      </Typo>
      <FilesInput inputRef={inputFile} />
    </PlaceholderSt>
  ) : (
    <PlaceholderSt icon={<Icon src={imageIcon} />} header="В сообществе нет фотографий">
      <Typo>Добавьте фотографии, чтобы создать товары</Typo>
    </PlaceholderSt>
  );
});
