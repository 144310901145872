import { Button } from "@vkontakte/vkui";
import React, { useRef } from "react";
import { Typo } from "lib/typo";
import { PlaceholderSt } from "./photosIsLocked";
import styled from "styled-components";
import alertIconRaw from "./img/alertIcon.svg";
import { i } from "lib/images";
import { FilesInput } from "./filesInput";

const Icon = styled.img`
  width: 56px;
`;

export const InstaError = () => {
  const inputFile = useRef(null);

  const uploadClicked = () => {
    inputFile.current.click();
  };

  return (
    <PlaceholderSt
      icon={<Icon src={i(alertIconRaw)} />}
      header="Ошибка загрузки файла"
      action={
        <Button size={"m"} onClick={uploadClicked}>
          Попробовать снова
        </Button>
      }
    >
      <Typo>Не удалось загрузить файл, попробуйте снова</Typo>
      <FilesInput inputRef={inputFile} />
    </PlaceholderSt>
  );
};
