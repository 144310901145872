import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  max-width: 100%;
`;

const MainCont = styled.div`
  flex-grow: 1;
`;

const Hint = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 35px;

  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  font-weight: normal;
  color: #6d7885;
`;

export const FormWithHint = ({ children, hint, className }) => {
  return (
    <Root className={className}>
      <MainCont>{children}</MainCont>
      <Hint>{hint}</Hint>
    </Root>
  );
};
