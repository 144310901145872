import { Page, Router } from "@happysanta/router";
import { ANALYTICS_EVENTS, sendAnalytics } from "lib/analytics";

// PAGES
export const PAGE_WELCOME = "/";
export const PAGE_GROUPS_LIST = "/groupsList";
export const PAGE_MARKET_LIST = "/marketList";
export const PAGE_PHOTOS_LIST = "/photosList/:addMore?";
export const PAGE_ALBUM_PHOTOS_LIST = "/albumPhotosList/:addMore?";
export const PAGE_PHOTO_ITEM = "/photoItem/:addMore?";
export const PAGE_MARKET_CREATE = "/marketCreate/:showAdditional?";
export const PAGE_SORT_PHOTOS = "/sortPhotos";
export const PAGE_SELECT_RUBRIC = "/selectRubric";
export const PAGE_SELECT_MARKET_ALBUM = "/selectMarketAlbum";

// export const PAGE_DEBUG = "/debug";

// HOME (view and panels)
export const PANEL_WELCOME = "PANEL_WELCOME";
export const PANEL_GROUPS_LIST = "PANEL_GROUPS_LIST";
export const PANEL_MARKET_LIST = "PANEL_MARKET_LIST";
export const PANEL_MARKET_CREATE = "PANEL_MARKET_CREATE";
export const PANEL_PHOTOS_LIST = "PANEL_PHOTOS_LIST";
export const PANEL_ALBUM_PHOTOS_LIST = "PANEL_ALBUM_PHOTOS_LIST";
export const PANEL_PHOTO_ITEM = "PANEL_PHOTO_ITEM";
export const PANEL_SORT_PHOTOS = "PANEL_SORT_PHOTOS";
export const PANEL_SELECT_RUBRIC = "PANEL_SELECT_RUBRIC";
export const PANEL_SELECT_MARKET_ALBUM = "PANEL_SELECT_MARKET_ALBUM";

// TESTS (view and panels)
export const VIEW_MAIN = "VIEW_MAIN";
export const VIEW_SELECT = "VIEW_SELECT";

// DEBUG
// export const VIEW_DEBUG = "VIEW_DEBUG";
// export const PANEL_DEBUG = "PANEL_DEBUG";

// MODALS
export const MODAL_GENERATE_DESCRIPTION = "MODAL_GENERATE_DESCRIPTION";
export const MODAL_HELP = "MODAL_HELP";
export const MODAL_WELCOME_BACK = "MODAL_WELCOME_BACK";

export const POPOUT_ALERT = "POPOUT_ALERT";
export const POPOUT_ACTIONS = "POPOUT_ACTIONS";
export const POPOUT_CONFIRM = "POPOUT_CONFIRM";

export const routes = {
  // home
  [PAGE_WELCOME]: new Page(PANEL_WELCOME, VIEW_MAIN),
  [PAGE_GROUPS_LIST]: new Page(PANEL_GROUPS_LIST, VIEW_MAIN),
  [PAGE_MARKET_LIST]: new Page(PANEL_MARKET_LIST, VIEW_MAIN),
  [PAGE_PHOTOS_LIST]: new Page(PANEL_PHOTOS_LIST, VIEW_MAIN),
  [PAGE_ALBUM_PHOTOS_LIST]: new Page(PANEL_ALBUM_PHOTOS_LIST, VIEW_MAIN),
  [PAGE_PHOTO_ITEM]: new Page(PANEL_PHOTO_ITEM, VIEW_MAIN),
  [PAGE_MARKET_CREATE]: new Page(PANEL_MARKET_CREATE, VIEW_MAIN),
  [PAGE_SORT_PHOTOS]: new Page(PANEL_SORT_PHOTOS, VIEW_MAIN),
  [PAGE_SELECT_RUBRIC]: new Page(PANEL_SELECT_RUBRIC, VIEW_SELECT),
  [PAGE_SELECT_MARKET_ALBUM]: new Page(PANEL_SELECT_MARKET_ALBUM, VIEW_SELECT),

  // debug
  // [PAGE_DEBUG]: new Page(PANEL_DEBUG, VIEW_DEBUG),
};

export const router = new Router(routes, { preventSameLocationChange: false, enableLogging: false });

router.on("update", (route) => {
  const { params } = route;
  let path = route.getLocation().replace(/\(.*?\)/g, "");
  Object.entries(params).forEach(([paramName, paramValue]) => {
    path = path.replace(`:${paramName}`, paramValue);
  });

  sendAnalytics({ ...ANALYTICS_EVENTS.pageOpened, label: path });
});

router.popPageToAndClear = (pageId) => {
  router.popPageToAndPush(router.history.getPageOffset(pageId) - 1, pageId);
};

// /**
//  * Удаляем последний location
//  */
// router.popPageAndClear = () => {
//   const prevLoc = router.getPreviousLocation()
//   router.popPageToAndReplace(router.history.currentIndex - 1, prevLoc.getPageId(), prevLoc.getParams())
// }

// router.on("update", (route, foo) => {
//   console.info("====== router changed =======", route, foo);
//   router.history.stack.forEach((histItem, index) => {
//     const { pageId, params } = histItem[0];
//     const pointer = index === router.history.getCurrentIndex() ? "->" : "";
//     console.info(pointer, pageId, JSON.stringify(params));
//   });
//   console.info("=======");
// });

router.start();

if (router.getCurrentLocation().getPageId() !== "/") {
  // console.info("redirect to main page");
  router.replacePage("/");
}

const origPopPage = router.popPage.bind(router);

router.popPage = () => {
  // console.info('replaced pop page')
  origPopPage();
};

const onRouterUpdateListeners = [];
export function addOnRouterUpdateListeners(listener) {
  onRouterUpdateListeners.push(listener);
}

router.on("update", (...args) => {
  onRouterUpdateListeners.forEach((listener) => listener(...args));
});
