import React from "react";
import styled from "styled-components";
import { Button, Div, FixedLayout, Separator, Counter } from "@vkontakte/vkui";

const ButtonSt = styled(Button)`
  color: #99a2ad;
  box-shadow: inset 0 0 0 1px #99a2ad;
`;

export function StartCreateFooter({ canStartCreate, onStartClick, photosCount }) {
  return (
    <FixedLayout filled vertical="bottom">
      <Separator wide />
      <Div>
        {canStartCreate ? (
          <Button
            size={"l"}
            stretched
            mode="primary"
            onClick={onStartClick}
            after={photosCount ? <Counter>{photosCount}</Counter> : null}
          >
            Продолжить
          </Button>
        ) : (
          <ButtonSt size={"l"} stretched disabled mode="outline">
            Выберите фотографии
          </ButtonSt>
        )}
      </Div>
    </FixedLayout>
  );
}
