import React from "react";
import ReactDOM from "react-dom";
import "lib/appParams";
import { AdaptivityProvider, AppRoot, ConfigProvider } from "@vkontakte/vkui";
import vkBridge from "@vkontakte/vk-bridge";
import { RouterContext } from "@happysanta/router";
import { App } from "./app";
import { router } from "model/router";
import "@vkontakte/vkui/dist/vkui.css";
import "./init";
import "./app.scss";
import { GlobalStyles } from "./globalStyles";

function setStatusBarStyles() {
  if (vkBridge.supports("VKWebAppSetViewSettings")) {
    vkBridge.send("VKWebAppSetViewSettings", { status_bar_style: "dark", action_bar_color: "#fff" });
  }
}

setTimeout(() => {
  vkBridge.send("VKWebAppInit");
  setStatusBarStyles();

  if (document.getElementById("preloader")) {
    document.getElementById("preloader").style.display = "none";
  }

  document.getElementById("root").style.opacity = "1";
  document.getElementById("root").style.transition = "opacity 0.7s ease-in-out";
}, 50);

// vkBridge.subscribe((e) => {
//   if (e.detail.type === "VKWebAppViewRestore") {
//     setStatusBarStyles();
//   }
// });

setInterval(setStatusBarStyles, 2000);

ReactDOM.render(
  <React.StrictMode>
    <RouterContext.Provider value={router}>
      <ConfigProvider>
        <AdaptivityProvider>
          <AppRoot>
            <GlobalStyles />
            <App />
          </AppRoot>
        </AdaptivityProvider>
      </ConfigProvider>
    </RouterContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
