import React from "react";
import styled from "styled-components";
import { Panel, Placeholder, Button } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { Typo } from "lib/typo";
import { getConfig } from "lib/config";

const { imagesDirPrefix } = getConfig();

const Desc = styled.div`
  font-size: 14px;
  line-height: 18px;
  max-width: 300px;
  margin: 0 auto;
`;

const Header = styled.div`
  font-family: VKSansDisplayBold, sans-serif;
  font-weight: 600;
`;

const Img = styled.img`
  max-width: 300px;
  display: block;
  margin: 0 auto;
  @media (max-width: 450px) {
    max-width: 100%;
  }
  @media (max-width: 350px) {
    max-width: 80%;
  }
`;

export const WelcomeScreen = observer(({ onStartClick, isLoading, user }) => {
  if (isLoading || user.authorized) {
    return <Panel id="welcome" />;
  }

  return (
    <Panel id="welcome">
      <Placeholder
        icon={<Img src={`${imagesDirPrefix}welcome.png`} alt="welcome" />}
        header={
          <Header>
            <Typo>
              Загружайте фотографии
              <br />и превращайте их в товары
            </Typo>
          </Header>
        }
        action={
          <Button size="l" onClick={onStartClick} stretched>
            Начать
          </Button>
        }
        stretched
      >
        <Desc>
          <Typo>
            Добавляйте в сообщество фото со своего устройства или из других соцсетей, чтобы мгновенно создавать карточки
            товаров
          </Typo>
        </Desc>
      </Placeholder>
    </Panel>
  );
});
