import { $instagram } from "../model/instagram";
import { Button, File, FormItem, Progress } from "@vkontakte/vkui";
import { Icon24Attach } from "@vkontakte/icons";
import React from "react";
import { observer } from "mobx-react-lite";

export const UploadInstagramDump = observer(() => {
  return (
    // <div style={{ marginTop: 10, borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc" }}>
    //   <div style={{ height: "2px" }}>
    //     {$instagram.uploadProgress > 0 && <Progress value={$instagram.uploadProgress} />}
    //   </div>
    //   <FormItem top="Upload instagram dump">
    //     <File
    //       disabled={$instagram.uploadProgress > 0}
    //       accept=".zip"
    //       onChange={(e) => {
    //         e.persist();
    //         $instagram.uploadZip(e.target.files[0]);
    //       }}
    //       before={<Icon24Attach />}
    //       controlSize="m"
    //       mode="secondary"
    //     >
    //       {$instagram.uploadProgress > 0 ? "Ждем ... " : "Выберите файл"}
    //     </File>
    //   </FormItem>
    //
    //   <Button onClick={() => $instagram.getUploadedPhotos()}>get uploaded photos</Button>
    // </div>
    <></>
  );
});
