import { GET, POST } from "./call";
import { $instagram } from "../model/instagram";

export async function uploadZip(files, groupId) {
  const formData = new FormData();

  for (const index in files) {
    formData.append("file" + index, files[index]);
  }
  formData.append("groupId", groupId);

  const config = {
    onUploadProgress: (progressEvent) => {
      let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (percent < 95) {
        $instagram.setUploadPercent(percent);
      }
    },
  };

  return POST("/instagram/uploadZip", formData, config);
}

export async function clearPhotos(groupId) {
  return POST("/instagram/clearPhotos", { groupId });
}

export async function getUploadedPhotos(groupId, offset) {
  return GET(`/instagram/${groupId}/photos?offset=${offset}`);
}

export async function checkStatus(groupId) {
  return GET(`/instagram/checkStatus?groupId=${groupId}`);
}
