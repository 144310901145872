import bridge from "@vkontakte/vk-bridge";
import Bottleneck from "bottleneck";
import { getConfig } from "lib/config";
import { appId } from "lib/appParams";

const { storyImagesPrefix } = getConfig();

export const postStory = (imageUrl) => {
  return bridge.send("VKWebAppShowStoryBox", {
    background_type: "image",
    url: imageUrl,
    locked: true,
    stickers: [
      {
        sticker_type: "renderable",
        sticker: {
          content_type: "image",
          url: `${storyImagesPrefix}story_button.png`,
          can_delete: 0,
          transform: { relation_width: 0.6, translation_y: 0.38 },
          clickable_zones: [
            {
              action_type: "app",
              action: { app_id: appId },
              clickable_area: [
                {
                  x: 0,
                  y: 0,
                },
                {
                  x: 300,
                  y: 0,
                },
                {
                  x: 300,
                  y: 70,
                },
                {
                  x: 0,
                  y: 70,
                },
              ],
            },
          ],
        },
      },
      {
        sticker_type: "renderable",
        sticker: {
          content_type: "image",
          url: `${storyImagesPrefix}story_mask.png`,
          can_delete: 0,
          transform: { translation_y: 1 },
        },
      },
    ],
  });
};

export const subscribeToPush = () => {
  return bridge.send("VKWebAppAllowNotifications");
};

const limiter = new Bottleneck({
  minTime: 333,
});

async function callApiMethodUnwrapped(method, params, { token } = {}) {
  const resp = await bridge.send("VKWebAppCallAPIMethod", {
    method: method,
    params: {
      v: "5.158",
      ...params,
      access_token: token,
    },
  });

  return resp.response;
}

export const callApiMethod = limiter.wrap(callApiMethodUnwrapped);

export async function getAccessToken(scope) {
  const resp = await bridge.send("VKWebAppGetAuthToken", {
    scope: scope,
    app_id: appId,
  });

  const neededScopes = (scope || "").split(",");
  const grantedScopes = resp.scope.split(",");

  const allGranted = neededScopes.every((scope) => grantedScopes.includes(scope));
  if (!allGranted) throw new Error("Not all scopes granted");

  return resp.access_token;
}
