import React from "react";
import { observer } from "mobx-react-lite";
import { startMarketCreateClicked } from "useCases";
import { $photos } from "model/photos";
import { router } from "model/router";
import { PhotosItemScreen } from "screens/photos/photosItem";
import { $instagram } from "model/instagram";
import { $groups } from "model/groups";
import { $commonPhotos } from "../../model/commonPhotos";

export const PhotoItem = observer(() => {
  const isInstagram = $groups.isInstagram;

  return isInstagram ? (
    <PhotosItemScreen
      isInstagram
      photo={$commonPhotos.openedPhoto}
      canStartCreate={$commonPhotos.canStartCreate}
      togglePhoto={(photo) => {
        $commonPhotos.togglePhotoSelect(photo.id);
      }}
      onStartClick={startMarketCreateClicked}
      onBackClick={() => router.popPage()}
      selectedPhotos={$commonPhotos.selectedPhotos}
    />
  ) : (
    <PhotosItemScreen
      photo={$commonPhotos.openedPhoto}
      canStartCreate={$commonPhotos.canStartCreate}
      togglePhoto={(photo) => {
        $commonPhotos.togglePhotoSelect(photo.id);
      }}
      onStartClick={startMarketCreateClicked}
      onBackClick={() => router.popPage()}
      selectedPhotos={$commonPhotos.selectedPhotos}
    />
  );
});
