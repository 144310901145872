import React from "react";
import { Spinner } from "@vkontakte/vkui";
import { InfScrollLib } from "./infScrollLib";

export const InfScroll = ({ onReachEnd: onReachEndOrig, allDataLoaded, children, root }) => {
  const inProgressRef = React.useRef(false);

  React.useEffect(() => {
    if (root) {
      root.style["overflow-y"] = "auto";
      return () => {
        root.style["overflow-y"] = "visible";
      };
    }
  }, [root]);

  const loaderComponent = allDataLoaded ? (
    <div />
  ) : (
    <div>
      <Spinner size="large" style={{ margin: "20px 0" }} />
    </div>
  );

  function onReachEnd() {
    if (inProgressRef.current) return;

    inProgressRef.current = true;

    if (allDataLoaded) {
      return new Promise(() => {});
    } else {
      return onReachEndOrig()
        .then(() => {
          inProgressRef.current = false;
        })
        .catch(() => {
          inProgressRef.current = false;
        });
    }
  }

  return (
    <InfScrollLib loader={loaderComponent} onReachEnd={onReachEnd} root={root}>
      {children}
    </InfScrollLib>
  );
};
