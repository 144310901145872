import React from "react";
import { observer } from "mobx-react-lite";
import { $market } from "model/market";
import { MODAL_GENERATE_DESCRIPTION, PAGE_PHOTOS_LIST, PAGE_SORT_PHOTOS, router } from "model/router";
import { CreateMarketScreen } from "screens/market/createMarket";
import { deletePhotoClicked, markCoverClicked, onAddPhotoClick, photosSorted, saveMarketItemClicked } from "useCases";
import { $modals } from "model/modals";
import { $groups } from "model/groups";
import { $commonPhotos } from "../../model/commonPhotos";

export const CreateMarket = observer(() => {
  if (!$market.formItem) return null;

  return (
    <CreateMarketScreen
      item={$market.formItem}
      isEdit={$market.isEdit}
      photos={$commonPhotos.selectedPhotos}
      categories={$market.flatCategories}
      albums={$market.albums}
      generateDescAvailable={$commonPhotos.photosWithText.length > 0}
      errors={$market.formErrors}
      formOnChange={$market.formOnChange}
      onBackClick={() => router.popPage()}
      createMarketClicked={saveMarketItemClicked}
      onCreateClick={() => router.pushPage(PAGE_PHOTOS_LIST)}
      onGenerateDescClick={() => $modals.show(MODAL_GENERATE_DESCRIPTION)}
      onAddPhotoClick={onAddPhotoClick}
      deletePhotoClicked={deletePhotoClicked}
      markCoverClicked={markCoverClicked}
      sortPhotosClicked={() => router.pushPage(PAGE_SORT_PHOTOS)}
      photosSorted={photosSorted}
      showAdditional={$market.showAdditional}
      setShowAdditional={(val) => $market.setShowAdditional(val)}
      isAdvanced={$groups.isAdvanced}
    />
  );
});
