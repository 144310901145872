import React from "react";
import styled from "styled-components";
import { i } from "lib/images";
import checkboxSvgRaw from "./img/check_circle_on_24.svg";
import shadowImgRaw from "./img/checkboxShadow.png";

const checkboxSvg = i(checkboxSvgRaw);
const shadowImg = i(shadowImgRaw);

const Root = styled.div`
  width: 60px;
  height: 60px;
  margin: -17px;
`;

const Cont = styled.div`
  position: relative;
  box-sizing: border-box;
`;

const Shadow = styled.img``;

const Circle = styled.div`
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 50%;
  width: 26px;
  height: 26px;

  position: absolute;
  top: 17px;
  left: 17px;
`;

const Checked = styled.img`
  position: absolute;
  top: 18px;
  left: 18px;
  opacity: ${(props) => (props.checked ? 1 : 0)};
  transition: opacity ease-in-out 0.1s;
`;

export function Checkbox({ checked, onClick, className }) {
  return (
    <Root className={className} onClick={onClick}>
      <Cont>
        <Shadow src={shadowImg} />
        <Circle />
        <Checked checked={checked} src={checkboxSvg} />
      </Cont>
    </Root>
  );
}
