import styled from "styled-components";

export const TwoCols = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  box-sizing: border-box;

  > * {
    width: calc((100% - 8px) / 2);
    margin-right: 8px;
  }
  > :nth-child(2n) {
    margin-right: 0;
  }
`;

export const ThreeCols = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  box-sizing: border-box;

  > * {
    width: calc((100% - 2 * 8px) / 3);
    margin-right: 8px;
  }
  > :nth-child(3n) {
    margin-right: 0;
  }
`;

export const ThreeOrTwoCols = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  box-sizing: border-box;

  > * {
    box-sizing: border-box;

    width: calc((100% - 8px) / 2);
    margin-right: 8px;
  }

  @media (max-width: 450px) {
    > :nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: 450px) {
    > * {
      width: calc((100% - 2 * 8px) / 3);
      margin-right: 8px;
    }
    > :nth-child(3n) {
      margin-right: 0;
    }
  }
`;
