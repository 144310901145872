import React from "react";
import { Panel, PanelHeaderBack, Tabs, TabsItem } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { FixedPanelHeader } from "ui/fixedHeader";
import { $photos } from "model/photos";
import { ANALYTICS_EVENTS, sendAnalytics } from "lib/analytics";
import { albumSelected, openInstPhotoClicked, openPhotoClicked, startMarketCreateClicked } from "useCases";
import { VkPhotosList } from "./vkPhotosList";
import { InstagramPhotosList } from "./instagramPhotosList";
import { $instagram } from "model/instagram";
import { $groups } from "model/groups";
import { $commonPhotos } from "model/commonPhotos";

export const PhotosListScreen = observer(({ onBackClick }) => {
  const ref = React.useRef(null);
  const [root, setRoot] = React.useState(null);

  React.useEffect(() => {
    setRoot(ref.current ? ref.current.parentNode : null);
  }, [ref]);

  const vkPhotosList = (
    <VkPhotosList
      albums={$photos.albums}
      root={root}
      onlyGroupPhotos={$commonPhotos.onlyGroupPhotos}
      alreadyAddedPhotos={$commonPhotos.alreadyAddedPhotos}
      canStartCreate={$commonPhotos.canStartCreate}
      togglePhoto={(photo) => {
        $commonPhotos.togglePhotoSelect(photo.id);
        sendAnalytics(ANALYTICS_EVENTS.photosListPhotoSelected);
      }}
      onAlbumClick={(album) => albumSelected(album)}
      onStartClick={startMarketCreateClicked}
      onPhotoClick={openPhotoClicked}
      loadMore={$photos.fetchNextPhotos}
      allDataLoaded={$photos.allPhotosLoaded}
      photosIsLocked={$photos.photosIsLocked}
      selectedPhotos={$commonPhotos.selectedPhotos}
    />
  );

  const instagramPhotosList = (
    <InstagramPhotosList
      photos={$commonPhotos.instagramPhotos}
      togglePhoto={(photo) => {
        $commonPhotos.togglePhotoSelect(photo.id);
        sendAnalytics(ANALYTICS_EVENTS.photosListPhotoSelected);
      }}
      onPhotoClick={openInstPhotoClicked}
      alreadyAddedPhotos={$commonPhotos.alreadyAddedPhotos}
      canStartCreate={$commonPhotos.canStartCreate}
      root={root}
      allDataLoaded={$instagram.allPhotosLoaded}
      loadMore={$instagram.fetchNextPhotos}
      onStartClick={startMarketCreateClicked}
      selectedPhotos={$commonPhotos.selectedPhotos}
    />
  );

  return (
    <Panel id="photosList" getRootRef={ref}>
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />} separator={false}>
        Фотографии
      </FixedPanelHeader>

      <Tabs>
        <TabsItem selected={$groups.sourceType === "instagram"} onClick={() => $groups.setSourceType("instagram")}>
          С устройства
        </TabsItem>
        <TabsItem selected={$groups.sourceType === "vk"} onClick={() => $groups.setSourceType("vk")}>
          Из сообщества
        </TabsItem>
      </Tabs>

      {$groups.sourceType === "vk" ? vkPhotosList : instagramPhotosList}
    </Panel>
  );
});
