import { types } from "mobx-state-tree";
import { getInitialHashParams } from "lib/appParams";

export const $debug = types
  .model({
    messages: types.optional(types.array(types.string), ["init value"]),
    isTabAvailable: types.optional(types.boolean, getInitialHashParams().debug === "1"),
  })
  .actions((self) => ({
    log(message) {
      self.messages.push(message);
    },
  }))
  .create();
