import { flow, types, getSnapshot, applySnapshot } from "mobx-state-tree";
import { callApiMethod } from "api/vkApps";
import { $token } from "./token";
import { $groups } from "./groups";
import { uploadMarketPhoto } from "api/vk";
import { getLargestVkPhoto } from "lib/images";
import { $alert } from "./alert";
import { PublicError } from "lib/errors";
import { router } from "./router";

function getImage(sizes = []) {
  const rPhoto = sizes.find((image) => image.type === "r");
  const yPhoto = sizes.find((image) => image.type === "y");
  const xPhoto = sizes.find((image) => image.type === "x");
  return rPhoto || yPhoto || xPhoto || sizes[0];
}

let initialState;

export const $photos = types
  .model({
    selectedAlbum: types.frozen({}),
    albums: types.frozen([]),
    albumPhotoIds: types.frozen([]), // id фоток выбранного альбома
    albumPhotosOffset: types.integer,
    allAlbumPhotosLoaded: types.boolean,
    photoIds: types.frozen([]), // id фоток для блока "Все фото"
    photosOffset: types.integer,
    allPhotosLoaded: types.boolean,
    photosMap: types.frozen({}), // все фотки когда либо загруженные для этой группы
    photosIsLocked: types.boolean,
  })
  .views((self) => ({
    get photos() {
      return self.photoIds.map((id) => self.photosMap[id]);
    },

    // фотографии группы, за исключением "вшитых в товар"
    get onlyGroupPhotos() {
      return self.photos.filter((photo) => !photo.marketItemId);
    },

    get albumPhotos() {
      return self.albumPhotoIds.map((id) => self.photosMap[id]);
    },
  }))
  .actions((self) => ({
    fetchNextPhotos: flow(function* () {
      const groupId = $groups.group.id;
      const token = $token.token;
      const albums = yield callApiMethod(
        "photos.getAlbums",
        { need_covers: 1, owner_id: -groupId, photo_sizes: 1 },
        { token }
      ).catch((e) => {
        const iosAccessDeniedError =
          e.error_data && e.error_data.error_reason && e.error_data.error_reason.error_code === 15;
        const androidAccessDeniedError = e.error_data && e.error_data.error_code === 15;

        if (iosAccessDeniedError || androidAccessDeniedError) {
          return { photosIsLocked: true, error: true };
        }

        $alert.show("Не удалось загрузить фотографии", "Ошибка", false, () => {
          router.popPage();
        });

        throw e;
      });

      if (!albums.photosIsLocked) {
        const count = 30;
        const photos = yield callApiMethod(
          "photos.getAll",
          { owner_id: -groupId, count, offset: self.photosOffset },
          { token }
        );
        self.allPhotosLoaded = self.photosOffset + count >= photos.count;
        self.photosOffset += count;
        self.albums = albums.items.map((item) => ({ ...item, calculatedThumb: getImage(item.sizes).src }));
        self.addPhotosToMap(photos.items);
        self.photoIds = [...self.photoIds, ...photos.items.map((photo) => photo.id)];
      } else {
        self.photosIsLocked = true;
      }
    }),

    setSelectedAlbum: function (album) {
      self.selectedAlbum = album;
      self.albumPhotoIds = [];
      self.albumPhotosOffset = 0;
      self.allAlbumPhotosLoaded = false;
    },

    fetchNextAlbumPhotos: flow(function* () {
      const groupId = $groups.group.id;
      const token = $token.token;
      const count = 30;
      const albumPhotos = yield callApiMethod(
        "photos.get",
        { album_id: self.selectedAlbum.id, owner_id: -groupId, count, offset: self.albumPhotosOffset },
        { token }
      );
      self.allAlbumPhotosLoaded = self.albumPhotosOffset + count >= albumPhotos.count;
      self.albumPhotosOffset += count;
      self.addPhotosToMap(albumPhotos.items);
      self.albumPhotoIds = [...self.albumPhotoIds, ...albumPhotos.items.map((photo) => photo.id)];
    }),

    uploadMarketPhoto: flow(function* (photoUrl, isMainPhoto) {
      console.info("uploadMarketPhoto", photoUrl, isMainPhoto);
      const groupId = $groups.group.id;
      const token = $token.token;
      const { upload_url } = yield callApiMethod(
        "photos.getMarketUploadServer",
        { group_id: groupId, main_photo: isMainPhoto ? 1 : 0 },
        { token }
      );
      const uploadResp = yield uploadMarketPhoto({ uploadServer: upload_url, photoUrl });

      if (uploadResp.error && uploadResp.error.includes("ERR_UPLOAD_BAD_IMAGE_SIZE")) {
        throw new PublicError("Попробуйте ещё раз или сообщите о проблеме", "Не удалось загрузить фото", true);
      }

      const [res] = yield callApiMethod("photos.saveMarketPhoto", { group_id: groupId, ...uploadResp }, { token });
      return res;
    }),

    addPhotosToMap(photos, marketItemId = 0) {
      const newPhotosMap = {};
      photos.forEach((photo) => {
        const largestSizes = getLargestVkPhoto([...photo.sizes]);
        newPhotosMap[photo.id] = { ...photo };
        const thumb = getImage(photo.sizes);
        newPhotosMap[photo.id].calculatedThumb = thumb.url;
        newPhotosMap[photo.id].isValid = largestSizes.height >= 400 && largestSizes.width >= 400;
        if (largestSizes.width === 0 || largestSizes.height === 0) newPhotosMap[photo.id].isValid = true;
        newPhotosMap[photo.id].marketItemId = marketItemId;
        newPhotosMap[photo.id].photoSource = "vk";
      });
      self.photosMap = { ...self.photosMap, ...newPhotosMap };
    },

    addPhotosToCurrentList(ids) {
      const photoIds = [...self.photoIds];
      ids.forEach((id) => {
        if (!photoIds.includes(id)) photoIds.unshift(id);
      });
      self.photoIds = photoIds;
    },

    removeMarketPhotos() {
      self.photoIds = self.photos.filter((photo) => !photo.marketItemId).map((photo) => photo.id);
    },

    afterCreate() {
      initialState = getSnapshot(self);
    },
    reset() {
      applySnapshot(self, initialState);
    },
  }))
  .create({
    albums: [],
    photosMap: {},
    photoIds: [],
    albumPhotosOffset: 0,
    allAlbumPhotosLoaded: false,
    photosOffset: 0,
    allPhotosLoaded: false,
    photosIsLocked: false,
  });
