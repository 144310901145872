import React from "react";
import styled from "styled-components";
import { Panel, Group, List, Cell, PanelHeaderBack } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { Icon24Done } from "@vkontakte/icons";
import { FixedPanelHeader } from "ui/fixedHeader";

const Item = styled.div`
  font-weight: ${(props) => (props.isRoot ? "bold" : "normal")};
  margin-left: ${(props) => (props.isRoot ? "0px" : "16px")};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectRubricScreen = observer(({ categories, categoryId, onBackClick, onSelected }) => {
  return (
    <Panel id="selectRubric">
      <FixedPanelHeader left={<PanelHeaderBack onClick={onBackClick} label={"Назад"} />}>
        Выбор категории
      </FixedPanelHeader>

      <Group>
        <List>
          {categories.map((cat) => (
            <Cell
              key={cat.id}
              onClick={() => onSelected(cat)}
              after={cat.id === categoryId ? <Icon24Done fill="var(--accent)" /> : null}
            >
              <Item isRoot={cat.isRoot}>{cat.name}</Item>
            </Cell>
          ))}
        </List>
      </Group>
    </Panel>
  );
});
