import React from "react";
import { observer } from "mobx-react-lite";
import { Snackbar, Avatar } from "@vkontakte/vkui";
import { Icon16Done, Icon16Block } from "@vkontakte/icons";
import { $popouts } from "model/popouts";

export const SnackbarCont = observer(() => {
  const onClose = () => $popouts.close();
  const { curPopoutParams } = $popouts;

  return (
    <>
      {curPopoutParams && (
        <Snackbar
          onClose={onClose}
          before={
            curPopoutParams.isBlock ? (
              <Icon16Block fill="#ccc" width={24} height={24} />
            ) : (
              <Avatar size={24} style={{ background: "var(--accent)" }}>
                <Icon16Done fill="#fff" width={14} height={14} />
              </Avatar>
            )
          }
        >
          {curPopoutParams.text}
        </Snackbar>
      )}
    </>
  );
});
