import React from "react";
import { observer } from "mobx-react-lite";
import { createMarketItemClicked, startEditClick } from "useCases";
import { MarketListScreen } from "screens/market/marketList";
import { $market } from "model/market";
import { router } from "model/router";

export const MarketList = observer(() => {
  return (
    <MarketListScreen
      marketIsLocked={$market.marketIsLocked}
      items={$market.items}
      onBackClick={() => router.popPage()}
      onItemClick={(item) => startEditClick(item)}
      onCreateClick={createMarketItemClicked}
      loadMore={$market.fetchNextMarketItems}
      allDataLoaded={$market.allItemsLoaded}
      noMarketItems={$market.noMarketItems}
    />
  );
});
