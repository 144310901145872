import React from "react";
import { observer } from "mobx-react-lite";
import { SelectRubricScreen } from "screens/selectRubric";
import { $market } from "model/market";
import { categorySelected } from "useCases";
import { router } from "model/router";
import { debounce } from "lib/debounce";

const debouncedCategorySelected = debounce(categorySelected, 100);

export const SelectRubric = observer(() => {
  return (
    <SelectRubricScreen
      categories={$market.flatCategories}
      categoryId={$market.formItem.category_id}
      onSelected={debouncedCategorySelected}
      onBackClick={() => router.popPage()}
    />
  );
});
