import React from "react";
import { observer } from "mobx-react-lite";
import { router } from "model/router";
import { SortPhotosScreen } from "screens/photos/sortPhotos";
import { continueAfterPhotosSortingClicked, photosSorted } from "useCases";
import { $commonPhotos } from "../../model/commonPhotos";

export const SortPhotos = observer(() => {
  return (
    <SortPhotosScreen
      photos={$commonPhotos.selectedPhotos}
      onBackClick={() => router.popPage()}
      onOrderChanged={photosSorted}
      onContinueClick={continueAfterPhotosSortingClicked}
    />
  );
});
