import styled from "styled-components";
import React from "react";

const ImageItemSt = styled.div`
  cursor: pointer;
`;

const ImgRounder = styled.div`
  border-radius: 6px;
  border: 0.5px solid rgba(0, 0, 0, 0.08);

  box-sizing: border-box;
  overflow: hidden;
`;

const ImageItemImg = styled.div`
  width: ${(props) => (props.imgWidth ? props.imgWidth : "120px")};
  height: ${(props) => (props.imgHeight ? props.imgHeight : "100px")};
  background: url(${(props) => props.src}) no-repeat center;
  background-size: cover;
`;

const Hint = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  color: #76818d;
  text-align: center;
  margin-top: 4px;
`;

export const ImageItem = React.forwardRef(({ src, isCover, onClick, imgWidth, imgHeight, className }, ref) => {
  return (
    <ImageItemSt onClick={onClick} ref={ref} className={className}>
      <ImgRounder>
        <ImageItemImg src={src} imgWidth={imgWidth} imgHeight={imgWidth} />
      </ImgRounder>
      {isCover && <Hint>Обложка</Hint>}
    </ImageItemSt>
  );
});
