import React from "react";
import { $spinner } from "model/spinner";
import { $token } from "model/token";
import { $groups } from "model/groups";
import {
  MODAL_WELCOME_BACK,
  PAGE_ALBUM_PHOTOS_LIST,
  PAGE_GROUPS_LIST,
  PAGE_MARKET_CREATE,
  PAGE_MARKET_LIST,
  PAGE_PHOTO_ITEM,
  PAGE_PHOTOS_LIST,
  PAGE_SELECT_MARKET_ALBUM,
  PAGE_SELECT_RUBRIC,
  router,
} from "model/router";
import { $market } from "model/market";
import { $photos } from "model/photos";
import { $popouts } from "model/popouts";
import { $alert } from "model/alert";
import { PublicError } from "lib/errors";
import { logMarketSaveStat } from "api/marketSaveStats";
import { ANALYTICS_EVENTS, sendAnalytics } from "lib/analytics";
import { $user } from "model/user";
import { authorize } from "api/auth";
import { appId, getInitialHashParams, isDesktopSafari } from "lib/appParams";
import { isNetworkVkError } from "lib/network";
import { getConfig } from "lib/config";
import { $modals } from "model/modals";
import { $instagram } from "model/instagram";
import { $commonPhotos } from "model/commonPhotos";
import { $appFlow } from "model/appFlow";
import { $confirm } from "model/confirm";
import { clearPhotos } from "api/instagram";
import { texts } from "lib/texts";
import { Typo } from "lib/typo";

async function doWithSpinner(func, onError) {
  try {
    $spinner.show();
    await func();
    $spinner.hide();
  } catch (e) {
    console.error("e", e);
    $spinner.hide();
    if (onError) onError(e);
  }
}

export async function getTokenAndGoNext() {
  return doWithSpinner(
    async () => {
      await $token.fetchToken();

      if (appId === 7986607) {
        groupSelected({ id: 193556985, market: { type: "advanced" } });
        return;
      }

      router.replacePage(PAGE_GROUPS_LIST);

      // фиксим ошибку с десктопным сафари, когда он не переходит на последний экран
      if (isDesktopSafari) {
        router.pushPage(PAGE_GROUPS_LIST);
      }

      if ($user.user.authorized) {
        setTimeout(() => {
          if (
            !getConfig().disableWelcomeBackModal &&
            router.getCurrentLocation().getPageId() === PAGE_GROUPS_LIST &&
            !getInitialHashParams().uploadedToGroup
          )
            $modals.show(MODAL_WELCOME_BACK);
        }, 1000);
      }
    },
    (e) => {
      if (isNetworkVkError(e)) {
        $alert.show("Проверьте подключение к интернету");
      } else {
        $alert.show(
          "Для продолжения работы приложению необходим доступ к товарам, фотографиям и сообществам",
          "Ошибка",
          false,
          () => {
            getTokenAndGoNext();
          }
        );
      }
    }
  );
}

export function startClicked() {
  return doWithSpinner(async () => {
    sendAnalytics(ANALYTICS_EVENTS.appStartClicked);
    authorize().catch(() => {});
    await getTokenAndGoNext();
  });
}

export async function authUser() {
  return doWithSpinner(async () => {
    await $user.authUser();
    if ($user.user.authorized) {
      await getTokenAndGoNext();
    }
  });
}

export async function loadGroupsList() {
  return doWithSpinner(async () => {
    if (!$groups.groupsLoaded) await $groups.fetchUserGroups();
  });
}

export async function groupSelected(group) {
  if ($appFlow.isLocked) return;

  return doWithSpinner(async () => {
    $groups.setGroup(group);
    $market.reset();
    $photos.reset();
    $instagram.reset();
    router.pushPage(PAGE_MARKET_LIST);
    sendAnalytics(ANALYTICS_EVENTS.appGroupSelected);
  });
}

export function albumSelected(album) {
  $photos.setSelectedAlbum(album);
  const { addMore } = router.getCurrentLocation().getParams();
  router.pushPage(PAGE_ALBUM_PHOTOS_LIST, { addMore });
}

export function openPhotoClicked(photo) {
  const { addMore } = router.getCurrentLocation().getParams();
  $commonPhotos.setOpenedPhoto(photo.id);
  router.pushPage(PAGE_PHOTO_ITEM, { addMore });
}

export function openInstPhotoClicked(photo) {
  const { addMore } = router.getCurrentLocation().getParams();
  $commonPhotos.setOpenedPhoto(photo.id);
  router.pushPage(PAGE_PHOTO_ITEM, { addMore });
}

/**
 * Кликнул "создать товар" в списке товаров
 */
export function createMarketItemClicked() {
  if ($market.totalMarketItemsCount >= 15000) {
    $alert.show("В сообществе нельзя создать больше 15 000 товаров", "Слишком много товаров");
  } else {
    $commonPhotos.setSelectedPhotos([]);
    $photos.removeMarketPhotos();
    $commonPhotos.saveAlreadyAddedPhotos();
    router.pushPage(PAGE_PHOTOS_LIST);
    sendAnalytics(ANALYTICS_EVENTS.marketItemsCreateMarketItemClicked);
  }
}

/**
 * Кликнул "Продолжить" на экране выбора фото
 */
export function startMarketCreateClicked() {
  const { addMore } = router.getCurrentLocation().getParams();

  if (addMore) {
    // товар уже создается / редактируется
    router.popPageTo(PAGE_MARKET_CREATE);
  } else {
    // товар еще не начал создаваться / редактроваться
    $market.reset();
    $market.ensureCategories();
    $market.ensureMarketAlbums();
    $market.startCreateMarket();
    router.pushPage(PAGE_MARKET_CREATE);
  }

  sendAnalytics(ANALYTICS_EVENTS.photosListContinueClicked);
}

export function startEditClick(item) {
  $photos.removeMarketPhotos();
  $photos.addPhotosToMap(item.photos, item.id);
  $photos.addPhotosToCurrentList(item.photos.map((photo) => photo.id));
  $commonPhotos.setSelectedPhotos(item.photos.map((photo) => photo.id));
  $market.reset();
  $market.ensureCategories();
  $market.ensureMarketAlbums();
  $market.startEditMarket(item);
  router.pushPage(PAGE_MARKET_CREATE);

  sendAnalytics(ANALYTICS_EVENTS.marketItemsItemSelected);
}

export function saveMarketItemClicked() {
  return doWithSpinner(
    async () => {
      const isEdit = $market.isEdit;
      sendAnalytics({ ...ANALYTICS_EVENTS.createMarketItemSaveClicked, label: isEdit ? "edit" : "create" });
      const savedMarketId = await $market.validateAndSaveMarketItem();
      if (savedMarketId) {
        console.info("savedMarketId", savedMarketId);
        logMarketSaveStat({ marketId: savedMarketId, groupVkId: $groups.group.id, isCreateNew: !isEdit });

        $market.reset();
        router.popPageToAndClear(PAGE_MARKET_LIST);
        $popouts.showSnackBar(texts.getMarketItemSavedText(isEdit));
        sendAnalytics({ ...ANALYTICS_EVENTS.createMarketItemSaved, label: isEdit ? "edit" : "create" });
      }
    },
    (error) => {
      if (error instanceof PublicError) {
        $alert.show(error.message, error.header, error.showMessageLink);
      } else if (isNetworkVkError(error)) {
        $alert.show("Проверьте подключение к интернету");
      } else {
        $alert.show("Не удалось сохранить товар");
      }
    }
  );
}

export function selectedPhotoForDescription(photo) {
  $market.formOnChange({ name: "description", value: photo.text });
}

/**
 * Кликнул удалить фото в форме создания / редактирования товара
 * @param photo
 */
export function deletePhotoClicked(photo) {
  $commonPhotos.togglePhotoSelect(photo.id);
}

export function markCoverClicked(photo) {
  $commonPhotos.setCover(photo.id);
}

export function continueAfterPhotosSortingClicked() {
  $photos.ensureCoverIsFirst();
  router.popPage();
}

export function photosSorted(sortedPhotos) {
  if (sortedPhotos.length) {
    $commonPhotos.setSelectedPhotos(sortedPhotos.map((i) => i.id));
    $commonPhotos.setCover(sortedPhotos[0].id);
  }
}

export function categorySelected(category) {
  if (router.getCurrentLocation().getPageId() !== PAGE_SELECT_RUBRIC) return;

  $market.formOnChange({ name: "category_id", value: category.id });
  router.popPage();
}

export function marketAlbumSelected(album) {
  if (router.getCurrentLocation().getPageId() !== PAGE_SELECT_MARKET_ALBUM) return;

  $market.formOnChange({ name: "album_id", value: album.id });
  router.popPage();
}

export function onAddPhotoClick() {
  $commonPhotos.saveAlreadyAddedPhotos();
  router.pushPage(PAGE_PHOTOS_LIST, { addMore: "1" });
}

export async function clearPhotosClicked() {
  $confirm.show(
    <Typo>{texts.getConfirmClearPhotos().header}</Typo>,
    <Typo>{texts.getConfirmClearPhotos().message}</Typo>,
    {
      yesButtonClick: () => {
        doWithSpinner(async () => {
          await $commonPhotos.resetSelectOnUploadedPhotos();
          $instagram.reset();
          await clearPhotos($groups.group.id);
          await $instagram.checkStatus();
        });
      },
      yesButtonTitle: "Удалить",
      yesButtonConfig: { mode: "destructive" },
    }
  );
}

export function filesSelected(files) {
  $commonPhotos.resetSelectOnUploadedPhotos();
  $instagram.filesSelected(files);
}
