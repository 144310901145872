import styled from "styled-components";

export const FormItemRightButton = styled.a`
  position: absolute;
  right: 16px;
  top: 14px;
  font-weight: normal;

  font-size: 14px;
  line-height: 18px;
  color: #3f8ae0;
  cursor: pointer;
  //:hover {
  //  opacity: 0.5;
  //}
`;
