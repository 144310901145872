import React from "react";
import styled from "styled-components";
import { ActionSheetItem, FormItem, Input, Textarea, HorizontalScroll, SelectMimicry } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import SortableList, { SortableItem } from "react-easy-sort";
import { FormItemRightButton } from "ui/formItemRightButton";
import { getImage300Width } from "lib/images";
import { FormWithHint } from "./formWithHint";
import { $actions } from "model/actions";
import { Icon28DeleteOutline, Icon28ListPlayOutline, Icon28Camera } from "@vkontakte/icons";
import arrayMove from "array-move";
import { PAGE_SELECT_RUBRIC, router } from "model/router";
import { disableEAndMinusOnKeyDown } from "lib/forms";
import { ImageItem } from "ui/imageItem";

const ImageItemsCont = styled.div`
  display: flex;

  > * {
    margin-right: 8px;
  }

  .Placeholder__in {
    padding: 0;
  }
`;

const FormItemWithRightButton = styled(FormItem)`
  position: relative;
`;

const HorizontalScrollSt = styled(HorizontalScroll)`
  .HorizontalScrollArrow__icon {
    margin-top: -18px;
  }
`;

const NoPhotosRoot = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  > div {
    color: #76818d;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #f2f3f5;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 24px;
`;

function NoPhotosPlaceHolder({ onClick }) {
  return (
    <NoPhotosRoot onClick={onClick}>
      <Icon28Camera style={{ color: "#99A2AD" }} />
      <div>Добавить фото</div>
    </NoPhotosRoot>
  );
}

export const MainForm = observer(
  ({
    errors,
    categories,
    item,
    photos,
    formOnChange,
    onGenerateDescClick,
    onAddPhotoClick,
    generateDescAvailable,
    deletePhotoClicked,
    markCoverClicked,
    sortPhotosClicked,
    photosSorted,
  }) => {
    const onSortEnd = (oldIndex, newIndex) => {
      photosSorted(arrayMove(photos, oldIndex, newIndex));
    };

    function onPhotoClicked(photo) {
      $actions.show(
        <>
          {!photo.isCover && (
            <ActionSheetItem autoclose before={<Icon28ListPlayOutline />} onClick={() => markCoverClicked(photo)}>
              Сделать обложкой
            </ActionSheetItem>
          )}
          <ActionSheetItem
            autoclose
            mode="destructive"
            before={<Icon28DeleteOutline />}
            onClick={() => deletePhotoClicked(photo)}
          >
            Удалить фотографию
          </ActionSheetItem>
        </>
      );
    }

    const selectedCategory = categories.find((cat) => cat.id === item.category_id);

    return (
      <>
        <FormItem top="Категория">
          <SelectMimicry placeholder="Выберите категорию" onClick={() => router.pushPage(PAGE_SELECT_RUBRIC)}>
            {selectedCategory ? selectedCategory.name : undefined}
          </SelectMimicry>
        </FormItem>

        <FormItem top="Название" status={errors.title ? "error" : "default"} bottom={errors.title}>
          <Input
            type="text"
            name="title"
            placeholder="Введите название"
            value={item.title}
            onChange={(e) => formOnChange(e.currentTarget)}
          />
        </FormItem>

        <FormItem top="Стоимость" status={errors.price ? "error" : "default"} bottom={errors.price}>
          <FormWithHint hint="₽">
            <Input
              name="price"
              value={item.price ? item.price : item.priceString}
              onChange={(e) => formOnChange(e.currentTarget)}
              placeholder="Введите стоимость"
              onKeyDown={disableEAndMinusOnKeyDown}
            />
          </FormWithHint>
        </FormItem>

        <FormItemWithRightButton top="Фотографии" status={errors.photos ? "error" : "default"} bottom={errors.photos}>
          {photos.length !== 0 && <FormItemRightButton onClick={onAddPhotoClick}>Добавить еще</FormItemRightButton>}
          <HorizontalScrollSt showArrows getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
            <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged_item">
              <ImageItemsCont>
                {photos.map((photo) => (
                  <SortableItem key={photo.id}>
                    <ImageItem
                      src={
                        photo.photoSource === "instagram" ? photo.calculatedThumb : getImage300Width(photo.sizes).url
                      }
                      key={photo.id}
                      isCover={photo.isCover}
                      onClick={() => onPhotoClicked(photo)}
                    />
                  </SortableItem>
                ))}
              </ImageItemsCont>
            </SortableList>

            {photos.length === 0 && <NoPhotosPlaceHolder onClick={onAddPhotoClick} />}
          </HorizontalScrollSt>
        </FormItemWithRightButton>

        <FormItemWithRightButton
          top="Описание"
          status={errors.description ? "error" : "default"}
          bottom={errors.description}
        >
          <Textarea
            name="description"
            value={item.description}
            onChange={(e) => formOnChange(e.currentTarget)}
            placeholder="Введите описание"
          />
          {generateDescAvailable && (
            <FormItemRightButton onClick={onGenerateDescClick}>Скопировать у фото</FormItemRightButton>
          )}
        </FormItemWithRightButton>
      </>
    );
  }
);
