import { Button, Div, FixedLayout, Placeholder, Progress, Spinner } from "@vkontakte/vkui";
import React from "react";
import { subscribeToPush } from "api/vkApps";
import { $instagram } from "model/instagram";
import { observer } from "mobx-react-lite";
import { Typo } from "lib/typo";
import styled from "styled-components";
import { $user } from "model/user";

const PlaceholderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

export const InstaProcessing = observer(() => {
  const pushAfterLoad = () => {
    subscribeToPush()
      .then(() => {
        $user.setPushAllowed(true);
      })
      .catch(() => {
        $user.setPushAllowed(false);
      });
  };

  const zipLoader = (
    <>
      <PlaceholderWrapper>
        <Placeholder
          icon={
            <>
              <svg width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 0C35.2548 0 46 10.7452 46 24C46 37.2548 35.2548 48 22 48C15.0825 48 8.63644 45.0554 4.11253 40.0015C3.56 39.3843 3.61247 38.436 4.22973 37.8834C4.84698 37.3309 5.79528 37.3834 6.34781 38.0006C10.3084 42.4252 15.9449 45 22 45C33.598 45 43 35.598 43 24C43 12.402 33.598 3 22 3C15.4729 3 9.45138 5.9978 5.50602 11.0001L10.5 11C12.4444 11 12.4985 13.8356 10.662 13.9932L10.5 14H2C1.2203 14 0.579551 13.4051 0.506866 12.6445L0.5 12.5V4C0.5 3.17157 1.17157 2.5 2 2.5C2.7797 2.5 3.42045 3.09489 3.49313 3.85554L3.5 4L3.49935 8.71026C8.00848 3.25549 14.732 0 22 0ZM22 12C22.7797 12 23.4204 12.5949 23.4931 13.3555L23.5 13.5V23.379L29.5607 29.4393C30.1098 29.9885 30.1442 30.8576 29.6636 31.4467L29.5607 31.5607C29.0115 32.1098 28.1424 32.1442 27.5533 31.6636L27.4393 31.5607L20.9393 25.0607C20.6932 24.8145 20.5414 24.4916 20.5073 24.1483L20.5 24V13.5C20.5 12.6716 21.1716 12 22 12Z"
                  fill="#99A2AD"
                />
              </svg>
            </>
          }
          header="Архив загружается"
        >
          <Typo>Не покидайте экран, пока архив не загрузится</Typo>
        </Placeholder>
      </PlaceholderWrapper>
      <Div>
        <div style={{ paddingBottom: "24px" }}>
          <Progress value={$instagram.uploadPercent} />
        </div>
      </Div>
    </>
  );

  const jobLoader = (
    <>
      <PlaceholderWrapper>
        <Spinner size="large" style={{ margin: "0 0" }} />
        <Placeholder header="Архив загружен и обрабатывается">
          <Typo>
            Обычно это занимает до 24 часов. Обработка не прервётся, если вы закроете мини-приложение.
            {!$user.pushAllowed && (
              <>
                <br />
                <br />
                Вы можете получить уведомление о завершении обработки.
              </>
            )}
          </Typo>
        </Placeholder>
      </PlaceholderWrapper>
      {!$user.pushAllowed && (
        <Div>
          <Button size={"l"} stretched onClick={pushAfterLoad}>
            Хочу получить уведомление
          </Button>
        </Div>
      )}
    </>
  );

  return (
    <FixedLayout filled vertical="bottom">
      {$instagram.status === "zipProcessing" ? zipLoader : jobLoader}
    </FixedLayout>
  );
});
