import React from "react";
import styled from "styled-components";
import { ActionSheet, Alert, ActionSheetItem } from "@vkontakte/vkui";
import { $alert } from "model/alert";
import { $actions } from "model/actions";
import { POPOUT_ACTIONS, POPOUT_ALERT, POPOUT_CONFIRM } from "./model/router";
import { $confirm } from "./model/confirm";

const ProblemButton = styled.a`
  text-decoration: inherit;
  color: inherit;

  :visited {
    color: inherit;
  }
`;

function getAlert(location) {
  const { onCloseClick, onAlertClosed, showMessageLink, header, message } = location.getParams();

  function hide() {
    $alert.hide();
    if (onAlertClosed) onAlertClosed();
  }

  const actions = [
    {
      title: "Закрыть",
      mode: "cancel",
      action: () => {
        hide();
        if (onCloseClick) onCloseClick();
      },
    },
  ];

  if (showMessageLink) {
    actions.unshift({
      title: (
        <ProblemButton href={"https://vk.me/ecom"} target="_blank">
          Сообщить о проблеме
        </ProblemButton>
      ),
      action: hide,
    });
  }

  return (
    <Alert
      onClose={() => {
        hide();
      }}
      actions={actions}
      actionsLayout="vertical"
      header={header}
      text={message}
    />
  );
}

function getConfirm(location) {
  const { actions, header, message } = location.getParams();

  function hide() {
    $confirm.hide();
  }

  return <Alert onClose={hide} actions={actions} actionsLayout="horizontal" header={header} text={message} />;
}

export function getPopouts(location) {
  const popout = location.getPopupId();

  // alerts
  if (popout === POPOUT_ALERT) {
    return getAlert(location);
  }

  // confirm
  if (popout === POPOUT_CONFIRM) {
    return getConfirm(location);
  }

  // action sheet
  if (popout === POPOUT_ACTIONS) {
    return (
      <ActionSheet
        onClose={() => $actions.hide()}
        iosCloseItem={
          <ActionSheetItem autoclose mode="cancel">
            Отменить
          </ActionSheetItem>
        }
      >
        {$actions.actionItems.node}
      </ActionSheet>
    );
  }

  return null;
}
