import React from "react";
import { observer } from "mobx-react-lite";
import { Button, ModalCard } from "@vkontakte/vkui";
import styled from "styled-components";
import { Typo } from "lib/typo";
import { i } from "lib/images";
import logo from "./imgs/logo.png";

const Img = styled.img`
  width: 72px;
  height: 72px;
`;

const Header = styled.div`
  font-family: VKSansDisplayBold, sans-serif;
  font-weight: 600;
`;

export const HelpModal = observer(({ id, onClose }) => {
  return (
    <ModalCard
      id={id}
      icon={<Img src={i(logo)} />}
      header={
        <Header>
          <Typo>Есть вопросы по приложению?</Typo>
        </Header>
      }
      subheader={<Typo>Напишите в сообщество VK for Sellers, и разработчики ответят вам</Typo>}
      actions={
        <Button size="l" mode="primary" as={"a"} href={"https://vk.me/ecom"} target={"_blank"} onClick={onClose}>
          Задать вопрос
        </Button>
      }
    />
  );
});
