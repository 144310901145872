import React from "react";
import { filesSelected } from "useCases";

export function FilesInput({ inputRef }) {
  if (!inputRef) throw new Error("Provide inputRef");

  const handleUpload = (e) => {
    e.persist();
    filesSelected(e.target.files);
  };

  return (
    <input
      accept=".zip,.jpg,.jpeg,.png,.webp,.avif"
      onChange={(e) => handleUpload(e)}
      style={{ display: "none" }}
      ref={inputRef}
      type="file"
      multiple="multiple"
    />
  );
}
