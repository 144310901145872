import { getUtmParamsQueryString, queryParams } from "lib/appParams";
import { getConfig } from "lib/config";

const { metrikaId } = getConfig();
// console.info({ metrikaId });

const utmParamsQueryString = getUtmParamsQueryString();

if (!window.ym) window.ym = () => {};

const userId = queryParams.vk_user_id ? queryParams.vk_user_id : "unknown";
window.ym(metrikaId, "setUserID", userId);

const printToConsole = false;

export const ANALYTICS_EVENTS = {
  pageOpened: {
    category: "page",
    action: "opened",
    title: "Открыта страница",
  },
  appStarted: {
    category: "app",
    action: "started",
    title: "Приложение запущено",
  },
  appStartClicked: {
    category: "app",
    action: "startClicked",
    title: 'Кликнул на "Начать"',
  },
  appGroupSelected: {
    category: "app",
    action: "groupSelected",
    title: "Выбрал сообщество",
  },
  marketItemsItemSelected: {
    category: "marketItems",
    action: "itemSelected",
    title: "Кликнул на товар",
  },
  marketItemsCreateMarketItemClicked: {
    category: "marketItems",
    action: "createMarketItemClicked",
    title: "Кликнул на 'Создать товар' в списке товаров",
  },
  photosListPhotoSelected: {
    category: "photosList",
    action: "photoSelected",
    title: "Выбрал фото на экране списка фото",
  },
  photosListContinueClicked: {
    category: "photosList",
    action: "continueClicked",
    title: 'Нажал кнопку "Продолжить" на экране списка фото',
  },
  createMarketItemSaveClicked: {
    category: "createMarketItem",
    action: "saveClicked",
    title: "Нажал сохранить на странице товара",
    labels: {
      edit: "Нажал сохранить на странице товара (при редактировании)",
      create: "Нажал сохранить на странице товара (при создании)",
    },
  },
  createMarketItemSaved: {
    category: "createMarketItem",
    action: "saved",
    title: "Товар сохранен",
    labels: {
      edit: "Товар сохранен (при редактировании)",
      create: "Товар сохранен (при создании)",
    },
  },
};

function processPageView({ label }) {
  const pageUrl = `/page${label}`;
  window.gtag("event", "page_view", {
    page_title: pageUrl,
    page_location: pageUrl,
    page_path: pageUrl,
  });

  window.ym(metrikaId, "hit", label);

  if (printToConsole) console.info("sent", pageUrl);
}

function processEvent({ category, action, label }) {
  const pageUrl = `/event/${category}/${action}?${utmParamsQueryString}`;
  window.gtag("event", "page_view", {
    page_title: pageUrl,
    page_location: pageUrl,
    page_path: pageUrl,
  });
  window.ym(metrikaId, "reachGoal", pageUrl);
  if (printToConsole) console.info("sent", pageUrl);

  if (label) {
    const pageUrlWithLabel = `/event/${category}/${action}/${label}?${utmParamsQueryString}`;
    window.gtag("event", "page_view", {
      page_title: pageUrlWithLabel,
      page_location: pageUrlWithLabel,
      page_path: pageUrlWithLabel,
    });
    window.ym(metrikaId, "reachGoal", pageUrlWithLabel);
    if (printToConsole) console.info("sent", pageUrlWithLabel);
  }
}

export function sendAnalytics({ category, action, label }) {
  if (category === "page" && action === "opened") processPageView({ category, action, label });
  else processEvent({ category, action, label });
}

// для вставки в гугл док
// let events = [];
// Object.values(ANALYTICS_EVENTS).map((data) => {
//   events.push(`/event/${data.category}/${data.action}\t${data.title}\n`);
//   data.labels &&
//     Object.keys(data.labels).map((key) => {
//       events.push(`/event/${data.category}/${data.action}/${key}\t${data.labels[key]} \n`);
//     });
// });
// console.info(events.join(""));
