import { types } from "mobx-state-tree";
import { POPOUT_ALERT, router } from "./router";

export const $alert = types
  .model({})
  .actions(() => ({
    show(message, header = "Ошибка", showMessageLink = false, onCloseClick = null, onAlertClosed = null) {
      router.pushPopup(POPOUT_ALERT, { message, header, showMessageLink, onCloseClick, onAlertClosed });
    },
    hide() {
      router.popPage();
    },
  }))
  .create();
