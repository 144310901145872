import React from "react";
import styled from "styled-components";
import { FormWithHint } from "./formWithHint";
import { Input, FormItem, withAdaptivity, SelectMimicry } from "@vkontakte/vkui";
import { PAGE_SELECT_MARKET_ALBUM, router } from "model/router";
import { disableEAndMinusOnKeyDown } from "lib/forms";

const SizesCont = styled.div`
  //border: 1px solid black;
  display: flex;
  > * {
    flex-basis: 50px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
`;

const FormItemSt = styled(FormItem)``;

const FormWithHintDimensions = styled(FormWithHint)`
  margin-bottom: 12px;
`;

export const AdditionalForm = withAdaptivity(
  ({ errors, albums, item, formOnChange, isAdvanced }) => {
    const selectedAlbum = albums.find((album) => album.id === item.album_id);

    return (
      <div>
        <FormItemSt top="Габариты" status={errors.sizes ? "error" : "default"} bottom={errors.sizes}>
          {isAdvanced && (
            <FormWithHintDimensions hint={"мм"}>
              <SizesCont>
                <Input
                  type="number"
                  pattern="\d*"
                  name="dimension_width"
                  placeholder="Ширина"
                  value={item.dimension_width || ""}
                  onChange={(e) => formOnChange(e.currentTarget)}
                  onKeyDown={disableEAndMinusOnKeyDown}
                />
                <Input
                  type="number"
                  pattern="\d*"
                  name="dimension_height"
                  placeholder="Высота"
                  value={item.dimension_height || ""}
                  onChange={(e) => formOnChange(e.currentTarget)}
                  onKeyDown={disableEAndMinusOnKeyDown}
                />
                <Input
                  type="number"
                  pattern="\d*"
                  placeholder="Длина"
                  name="dimension_length"
                  value={item.dimension_length || ""}
                  onChange={(e) => formOnChange(e.currentTarget)}
                  onKeyDown={disableEAndMinusOnKeyDown}
                />
              </SizesCont>
            </FormWithHintDimensions>
          )}

          <FormWithHint hint={"г"}>
            <Input
              type="number"
              pattern="\d*"
              name="weight"
              placeholder="Масса"
              value={item.weight || ""}
              onChange={(e) => formOnChange(e.currentTarget)}
              onKeyDown={disableEAndMinusOnKeyDown}
            />
          </FormWithHint>
        </FormItemSt>

        {isAdvanced && (
          <FormItem
            top="Количество в наличии"
            status={errors.stock_amount ? "error" : "default"}
            bottom={errors.stock_amount}
          >
            <Input
              type="number"
              pattern="\d*"
              name="stock_amount"
              placeholder="Введите количество"
              value={item.stock_amount}
              onChange={(e) => formOnChange(e.currentTarget)}
              onKeyDown={disableEAndMinusOnKeyDown}
            />
          </FormItem>
        )}

        <FormItem top="Артикул" status={errors.sku ? "error" : "default"} bottom={errors.sku}>
          <Input
            type="text"
            name="sku"
            value={item.sku || ""}
            onChange={(e) => formOnChange(e.currentTarget)}
            placeholder="Введите артикул"
          />
        </FormItem>

        <FormItem top="Подборка">
          <SelectMimicry placeholder="Выберите подборку" onClick={() => router.pushPage(PAGE_SELECT_MARKET_ALBUM)}>
            {selectedAlbum ? selectedAlbum.title : undefined}
          </SelectMimicry>
          {/*<Select*/}
          {/*  name={"album_id"}*/}
          {/*  placeholder="Выберите подборку"*/}
          {/*  searchable={hasMouse ? true : undefined}*/}
          {/*  value={item.album_id || ""}*/}
          {/*  options={albums.map((album) => ({ value: album.id, label: album.title }))}*/}
          {/*  onChange={(e) => formOnChange(e.currentTarget)}*/}
          {/*/>*/}
        </FormItem>
      </div>
    );
  },
  {
    hasMouse: true,
  }
);
