import React from "react";
import { Panel, PanelHeaderButton, Placeholder, Spacing, Button } from "@vkontakte/vkui";
import { Typo } from "lib/typo";
import { FixedPanelHeader } from "ui/fixedHeader";
import { Icon24HelpOutline, Icon56InfoOutline } from "@vkontakte/icons";

export const NoGroups = ({ onHelpClick }) => {
  return (
    <Panel id="groupsList">
      <FixedPanelHeader
        left={
          <PanelHeaderButton onClick={onHelpClick}>
            <Icon24HelpOutline />
          </PanelHeaderButton>
        }
      >
        Сообщества
      </FixedPanelHeader>
      <Spacing />

      <Placeholder
        icon={<Icon56InfoOutline />}
        header={<Typo>Вы не управляете ни одним сообществом</Typo>}
        stretched
        action={
          <Button size="m" as="a" href="https://vk.com/@ecom-obnovlennyi-magazin" target="_blank">
            Создать сообщество
          </Button>
        }
      >
        <Typo>
          Чтобы создавать товары из фото, создайте сообщество или попросите сделать вас руководителем в существующем
        </Typo>
      </Placeholder>
    </Panel>
  );
};
